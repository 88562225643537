import React from "react";
import { Typography } from "@material-ui/core";
import GameReport from "./GameReport";
import TCButton from "./TCButton";
import TCDialog from "./TCDialog";
import { dbSupportLaterCountIncrement } from "../helpers/db";

export default class GameFinished extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openGameReport: false };
  }
  rank = (rankList, score) => {
    for (let i = rankList.length - 1; i > 0; --i) {
      const [points, rank] = rankList[i];
      if (score >= points) {
        return rank;
      }
    }
    const lowestRank = rankList[0][1];
    return lowestRank;
  };
  onGameReportOpen = () => {
    this.setState({ openGameReport: true });
  };
  onGameReportClose = () => {
    this.setState({ openGameReport: false });
  };
  onSupportLater = () => {
    dbSupportLaterCountIncrement();
  };
  render() {
    const {
      score,
      $$,
      game,
      themeComponent,
      newHighScore,
      language,
      newLevelUnlocked,
      challengeId,
    } = this.props;
    const { openGameReport } = this.state;
    const Theme = themeComponent;
    const TC = ({ children, style, ...props }) => (
      <Typography
        align="center"
        style={{ ...style, marginBottom: "8px" }}
        {...props}
      >
        {children}
      </Typography>
    );

    return (
      <div style={style.container}>
        <div style={style.section}>
          <TC style={style.points}>
            {score} {score === 1 ? $$("point") : $$("points")}
          </TC>
          {!challengeId && newHighScore && <TC>{$$("NEW HIGH SCORE!")}</TC>}
          {!challengeId && !newHighScore && (
            <TC>{$$("You did not beat your high score.")}</TC>
          )}
          {!challengeId && newLevelUnlocked && (
            <TC>{$$("YOU UNLOCKED THE NEXT LEVEL!")}</TC>
          )}
        </div>
        <div style={style.section}>
          <TC>{$$("TypoCop rates your performance:")}</TC>
          <TC style={style.performance}>
            <Theme $$={$$} language={language} score={score} output="text" />
          </TC>
          <Theme
            $$={$$}
            language={language}
            score={score}
            imageStyle={{ height: 80 }}
          />
        </div>

        <div
          style={{
            ...style.section,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: 200,
          }}
        >
          {challengeId ? null : (
            <TCButton onClick={this.props.onSameAgain}>
              {$$("PLAY AGAIN")}
            </TCButton>
          )}
          <TCButton onClick={this.props.onExit}>{$$("EXIT")}</TCButton>
        </div>
        <TCButton variant="text" onClick={this.onGameReportOpen}>
          {$$("Game report")}
        </TCButton>

        <TCDialog
          open={openGameReport}
          $$={$$}
          title={$$("GAME REPORT")}
          onClose={this.onGameReportClose}
        >
          <GameReport $$={$$} game={game} />
        </TCDialog>
      </div>
    );
  }
}

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  section: {
    padding: "20px 0px 20px 0px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  points: {
    fontSize: "34px",
    margin: "0px",
    padding: "0px",
    lineHeight: "1",
  },
  performance: {
    fontSize: "24px",
    margin: "0px",
    padding: "0px",
    lineHeight: "1",
  },
};
