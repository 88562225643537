import React from 'react'
import { Typography } from '@material-ui/core'
import Screen from './Screen'
import copImage from '../static/cop.png'
import TCButton from './TCButton'

export default class TCError extends React.Component {
  render() {
    const { error, showReloadButton, $$, language } = this.props
    const isEnglish = language === 'english'
    const isSwedish = language === 'swedish'
    const RF = React.Fragment
    let messages = null
    if ('string' === typeof error) {
      messages = [error]
    } else {
      messages = error
    }
    return (
      <Screen>
        <div style={style.container}>
          <div style={{ ...style.section }}>
            <img src={copImage} alt={$$('TypoCop logo')} />
          </div>
          {messages.map((message, index) => (
            <div key={`tcerror-message-${index}`} style={style.textsection}>
              <Typography align="left">{message}</Typography>
            </div>
          ))}
          {showReloadButton && (
            <RF>
              <div style={style.section}>
                <TCButton onClick={() => window.location.reload(true)}>
                  {$$('RELOAD')}
                </TCButton>
              </div>
              <div style={style.textsection}>
                <Typography>
                  {isEnglish && (
                    <RF>
                      Didn't it help? Reload a couple more times. Still no good?
                      Restart your browser. Still having issues? Hm. Network
                      problems, maybe? Sorry for the inconvenience! Please check
                      back later.
                    </RF>
                  )}
                  {isSwedish && (
                    <RF>
                      Hjälpte det inte? Ladda om några gånger till. Funkar det
                      fortfarande inte? Starta om din webbläsare. Fortfarande
                      problem? Hm. Nåt fel på nätverket, kanske? Ursäkta
                      strulet! Var vänlig återkom senare.
                    </RF>
                  )}
                </Typography>
              </div>
            </RF>
          )}
        </div>
      </Screen>
    )
  }
}

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    alignItems: 'center',
  },
  section: {
    paddingTop: 20,
  },
  textsection: {
    paddingTop: 20,
    width: '100%',
  },
}
