import React from 'react'
import { Typography } from '@material-ui/core'
import { Line } from 'react-chartjs-2'
import { mathRound } from '../helpers/math'

const datasetGet = ({ label, pointColor, lineColor, pointRadius, data }) => {
  // see: https://www.chartjs.org/docs/latest/
  // see: https://github.com/jerairrest/react-chartjs-2
  return {
    label,
    fill: false,
    lineTension: 0.1,
    backgroundColor: lineColor,
    borderColor: lineColor,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    borderWidth: 1,
    pointBorderColor: pointColor,
    pointBackgroundColor: pointColor,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: pointColor,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius,
    pointHitRadius: 10,
    data,
  }
}

const getData = (game, labelYou, labelAverage) => {
  const dp = [{ time: game.timeStart }, ...game.rounds]
  let dt = []
  let colors = []
  const myLineColor = 'rgb(0,191,255)' // deepskyblue
  const errorColor = 'rgba(255,0,0,1)'
  const okColor = 'rgba(0,128,0,1)'
  for (let i = 0; i < dp.length - 1; i++) {
    const penalty = dp[i + 1].penalty
    const dtword = dp[i + 1].time - dp[i].time
    dt.push(dtword + 1000 * penalty)
    colors.push(penalty > 0 ? errorColor : okColor)
  }
  const averagePace = mathRound((1000 * game.countdown) / dt.length, 1)
  const target = dt.map(_ => averagePace)
  const data = {
    labels: dt.map((_, idx) => idx + 1),
    datasets: [
      datasetGet({
        label: labelYou,
        pointColor: colors,
        lineColor: myLineColor,
        pointRadius: 2,
        data: dt,
      }),

      datasetGet({
        label: labelAverage,
        pointColor: 'rgba(0,0,0,1)',
        lineColor: 'rgba(0,0,0,1)',
        pointRadius: 0,
        data: target,
      }),
    ],
  }
  return data
}

export default class GameReport extends React.Component {
  render() {
    const { $$, game } = this.props
    const options = {
      legend: { labels: { fontColor: '#000' } },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: $$('Time (ms)'),
              fontColor: '#000',
            },
            ticks: {
              fontColor: '#000',
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: $$('Round'),
              fontColor: '#000',
            },
            ticks: {
              fontColor: '#000',
            },
          },
        ],
      },
    }
    return (
      <div style={{ paddingTop: 20 }}>
        <Typography>
          {$$('You played {0} rounds.', game.rounds.length)}
        </Typography>

        <Typography>
          {$$('Time (milliseconds) you spent compared to the average:')}
        </Typography>

        <div style={{ WebkitTapHighlightColor: 'rgba(0,0,0,0)' }}>
          <Line
            data={getData(game, $$('You'), $$('Average'))}
            options={options}
          />
        </div>

        <table
          style={{
            width: '100%',
            marginTop: 32,
            borderCollapse: 'separate',
            borderSpacing: '0 0.5em',
          }}
        >
          <tbody>
            {game.rounds.map((r, roundindex) => {
              const { text, typoIndex, chosenIndex } = r
              const succeeded = typoIndex === chosenIndex
              return (
                <tr key={`game-round-${roundindex}`}>
                  <td
                    style={{
                      width: 20,
                      maxWidth: 20,
                      verticalAlign: 'middle',
                      backgroundColor: succeeded ? 'green' : 'red',
                    }}
                  >
                    <Typography align="center">{`${roundindex +
                      1}`}</Typography>
                  </td>

                  <td style={{ verticalAlign: 'middle', paddingLeft: 4 }}>
                    <Typography gutterBottom>
                      {text.split(' ').map((w, wordindex) => {
                        let wordStyle = style.word
                        const isTypo = wordindex === typoIndex
                        wordStyle = {
                          ...wordStyle,
                          ...(isTypo ? style.typoWord : {}),
                          ...(wordindex === chosenIndex
                            ? isTypo
                              ? style.chosenRightWord
                              : style.chosenWrongWord
                            : {}),
                        }
                        return (
                          <span key={`word-${roundindex}-${wordindex}`}>
                            <span style={wordStyle}>{w}</span>{' '}
                          </span>
                        )
                      })}
                    </Typography>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

const style = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  word: {
    padding: 2,
  },
  typoWord: {
    fontWeight: 'bold',
  },
  chosenRightWord: {
    border: '2px solid green',
    borderRadius: 8,
  },
  chosenWrongWord: {
    border: '2px solid red',
    borderRadius: 8,
  },
}
