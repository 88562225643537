import React from 'react'
import AboutHowToPlay from './AboutHowToPlay'
import TCDialog from '../TCDialog'
import AboutAbout from './AboutAbout'

export default class About extends React.Component {
  constructor(props) {
    super(props)
    const { $$ } = this.props
    this.infos = [
      {
        // default
        label: $$('HOW TO PLAY'),
        id: 'howtoplay',
      },
      { label: $$('ABOUT'), id: 'about' },
    ]
  }
  render() {
    const { onClose, open, $$, infoID, themeComponent, language } = this.props
    const infoIndex = Math.max(0, this.infos.findIndex(t => t.id === infoID))
    const info = this.infos[infoIndex]

    return (
      <TCDialog title={info.label} open={open} $$={$$} onClose={onClose}>
        {info.id === 'howtoplay' && (
          <AboutHowToPlay
            $$={$$}
            language={language}
            themeComponent={themeComponent}
          />
        )}
        {info.id === 'about' && (
          <AboutAbout
            $$={$$}
            language={language}
            themeComponent={themeComponent}
          />
        )}
      </TCDialog>
    )
  }
}
