// const dateAddDays = (date, daysToAdd) => {
//   // date is empty or a string with a valid date format, e.g. '2018-07-22' or '2018-07-22T12:32:16+02:00'
//   // result is an ISO 8601 string
//   const nt = new Date(date).getTime() + daysToAdd * 864e5
//   const nd = new Date(nt).toISOString().slice(0, 10)
//   return nd
// }

export default {
  // expiresOn: dateAddDays(process.env.REACT_APP_BUILD_TIME, 30),
  countdown: 30,
}
