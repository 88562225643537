import React from "react";
import fileconfig from "../config";
import TCButton from "../components/TCButton";
import TCLoading from "../components/TCLoading";
import TCError from "../components/TCError";
import { bfLoad } from "../helpers/bf";
import { gameChallengeCreate } from "../helpers/game";
import Screen from "../components/Screen";
import About from "../components/About/About";
import ControlPanel from "../components/ControlPanel";
import Settings from "../components/Settings";
import { themeLoad, themeNameGet, themeLanguagesGet } from "../helpers/theme";
import { dbThemeActiveIdGet, dbThemeActiveIdSet } from "../helpers/db";
import Themes from "../components/Themes";
import { randomSeedReset } from "../helpers/random";
import PlainButton from "../components/PlainButton";
import LanguageChoice from "../components/LanguageChoice";
import TCHeader from "../components/TCHeader";
import { styles } from "../helpers/styles";
import { TCFooter } from "../components/Common";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export default class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      config: null,
      error: [],
      loadingBloomfilter: true,
      loadingTheme: true,
      openAbout: null,
      openSettings: false,
      openThemes: false,
      userMustReload: false,
    };
  }

  isExpired = () => {
    if (fileconfig.expiresOn === undefined) {
      return false;
    }
    const now = new Date();
    const expiresOn = new Date(fileconfig.expiresOn);
    return now >= expiresOn;
  };

  componentDidMount() {
    this.mounted = true;
    const { $$, language } = this.props;
    if (this.isExpired()) {
      this.onError($$("This site's TypoCop licence has expired. :("));
      return;
    }

    // theme
    const onThemeLoadSuccess = (theme) => {
      this.Theme = theme.default;
      this.challenges = this.Theme.challengesGet()[language];
      this.safeSetState({ loadingTheme: false });
      this.props.saveTheme(this.Theme);
    };
    const themeRequestOk = themeLoad(
      dbThemeActiveIdGet(),
      onThemeLoadSuccess,
      this.onResourceLoadingError
    );
    if (!themeRequestOk) {
      this.onError($$("Failed to load theme."));
    }

    // config
    const config = Object.assign({}, fileconfig);
    this.safeSetState({ config });
    this.props.saveConfig(config);

    // bloom filter
    const onBloomfilterLoadSuccess = (bloomfilter) => {
      this.bloomfilter = bloomfilter;
      this.safeSetState({ loadingBloomfilter: false });
      this.props.saveBloomfilter(bloomfilter);
    };
    const bfRequestOk = bfLoad(
      language,
      onBloomfilterLoadSuccess,
      this.onResourceLoadingError
    );
    if (!bfRequestOk) {
      this.onError($$("Failed to load dictionary."));
    }
  }

  safeSetState = (newState) => {
    // safely set state
    // async calls could finish after someone has unmounted the component (e.g. during tests),
    // and we must not call setState on unmounted components
    if (this.mounted) {
      this.setState(newState);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  onResourceLoadingError = (error) => {
    this.setState({ userMustReload: true });
  };

  onError = (newError) => {
    let { error } = this.state;
    error = [...error, newError];
    this.safeSetState({ error });
  };

  onAboutOpen = (infoID, extra = null) => () => {
    const openAbout = {
      infoID,
      extra,
    };
    this.safeSetState({ openAbout });
  };

  onSettingsOpen = () => {
    this.safeSetState({ openSettings: true });
  };

  onThemesOpen = () => {
    this.safeSetState({ openThemes: true });
  };

  onAboutClose = () => {
    this.safeSetState({ openAbout: null });
  };

  onSettingsClose = () => {
    this.safeSetState({ openSettings: false });
  };

  onThemesClose = () => {
    this.safeSetState({ openThemes: false });
  };

  onThemeSelect = (theme) => {
    dbThemeActiveIdSet(theme);
  };

  onPlay = (level) => {
    const { config } = this.state;
    const { history, makeUrl } = this.props;
    randomSeedReset();
    history.push(makeUrl("game"), {
      challenge: gameChallengeCreate(
        config,
        this.challenges[level],
        level,
        this.Theme.id
      ),
    });
  };

  render() {
    const {
      error,
      loadingBloomfilter,
      loadingTheme,
      openAbout,
      openSettings,
      openThemes,
      userMustReload,
    } = this.state;
    const { $$, makeUrl, history, language } = this.props;
    if (!this.mounted) {
      return null;
    }
    if (userMustReload) {
      const reloadError = [
        $$(
          "TypoCop failed to start, which usually means a new version is available."
        ),
        $$("Reload to update TypoCop:"),
      ];
      return (
        <TCError
          $$={$$}
          language={language}
          error={reloadError}
          showReloadButton={userMustReload}
        />
      );
    }
    if (error.length > 0) {
      return <TCError $$={$$} language={language} error={error} />;
    }
    if (loadingBloomfilter || loadingTheme) {
      return <TCLoading $$={$$} />;
    }
    const adminTestWords = false;
    const languages = themeLanguagesGet(this.Theme.id);
    return (
      <Screen style={this.Theme.style.HomeScreen}>
        <div style={styles.columnSpreadFill}>
          <TCHeader $$={$$}></TCHeader>

          {adminTestWords && (
            <TCButton onClick={() => history.push(makeUrl("admin-test-words"))}>
              ?
            </TCButton>
          )}

          <div style={styles.columnSpread}>
            <PlainButton onClick={this.onThemesOpen}>
              <Typography style={{ fontWeight: "bold" }}>
                {themeNameGet(this.Theme.id, language).toUpperCase()}
              </Typography>
            </PlainButton>
            <LanguageChoice
              $$={$$}
              languages={languages}
              selectedLanguageId={language}
            ></LanguageChoice>
            <ControlPanel
              $$={$$}
              language={language}
              onPlay={this.onPlay}
              themeComponent={this.Theme}
            />
            {/* <PlainButton component={Link} to="/c/000-012-345">
              <Typography style={{ fontWeight: "bold" }}>
                One-Off Challenge
              </Typography>
            </PlainButton> */}
          </div>

          <div style={styles.columnSpread}>
            <TCButton
              variant="text"
              onClick={this.onAboutOpen("howtoplay", {
                themeComponent: this.Theme,
                language,
              })}
            >
              {$$("HOW TO PLAY")}
            </TCButton>

            <div style={styles.rowSpread}>
              <TCButton
                variant="text"
                onClick={this.onAboutOpen("about", { language })}
              >
                {$$("ABOUT")}
              </TCButton>

              <TCButton variant="text" onClick={this.onSettingsOpen}>
                {$$("SETTINGS")}
              </TCButton>
            </div>

            <TCFooter $$={$$}></TCFooter>
          </div>
        </div>

        {openAbout && (
          <About
            infoID={openAbout.infoID}
            onClose={this.onAboutClose}
            open={true}
            $$={$$}
            themeComponent={this.Theme}
            language={language}
          />
        )}

        {openSettings && (
          <Settings
            themeId={this.Theme.id}
            onClose={this.onSettingsClose}
            open={true}
            $$={$$}
            language={language}
          />
        )}

        {openThemes && (
          <Themes
            themeId={this.Theme.id}
            onClose={this.onThemesClose}
            onSelect={this.onThemeSelect}
            open={true}
            $$={$$}
            language={language}
          />
        )}
      </Screen>
    );
  }
}
