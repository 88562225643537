import React from "react";
import { Typography } from "@material-ui/core";

export default class AboutPrivacy extends React.Component {
  render() {
    const { language } = this.props;
    const T = Typography;
    const RF = React.Fragment;
    const isEnglish = language === "english";
    const isSwedish = language === "swedish";
    return (
      <div>
        <T>
          {isEnglish && (
            <RF>
              TypoCop does not collect any Personally Identifiable Information
              (PII).
            </RF>
          )}
          {isSwedish && (
            <RF>
              TypoCop samlar inte in någon personligt identifierbar information
              (PII).
            </RF>
          )}
        </T>
        <T>
          {isEnglish && (
            <RF>
              TypoCop saves only Non-PII in the browser, for example your high
              scores, and that information never leaves the browser.
            </RF>
          )}
          {isSwedish && (
            <RF>
              TypoCop sparar endast icke-PII i webbläsaren, exempelvis dina
              rekord, och den informationen lämnar aldrig webbläsaren.
            </RF>
          )}
        </T>
        <T>
          {isEnglish && <RF>TypoCop is hosted on </RF>}
          {isSwedish && <RF>TypoCops webbhotell är </RF>}
          <a href="https://www.netlify.com/">Netlify</a>.
        </T>
        <T>
          {isEnglish && (
            <RF>
              When I last checked, Netlify stated that they keep access logs
              with IP addresses of visitors for 30 days. That means that when
              you vist this website, your IP ends up in a log on Netlify for 30
              days. This is perfectly reasonable, in my opinion. All hosting
              service providers must keep such logs in order to provide a good
              service. After a while, that information is no longer needed and
              should be deleted, which Netlify does. For more information, read
              about <a href="https://www.netlify.com/gdpr/">GDPR on Netlify</a>.
            </RF>
          )}
          {isSwedish && (
            <RF>
              När jag senast kontrollerade så meddelade Netlify att de sparar
              åtkomstloggar med besökarnas IP-adresser i 30 dagar. Det betyder
              att när du besöker den här webbsidan så hamnar din IP i en logg
              hos Netlify där den finns kvar i 30 dagar. Det här är helt i sin
              ordning enligt mig. Alla webbhotell måste ha sådana loggar för att
              kunna leverera en bra tjänst. Efter en tid behövs inte den
              informationen längre och borde raderas, vilket Netlify gör. För
              mer information, läs om{" "}
              <a href="https://www.netlify.com/gdpr/">GDPR på Netlify</a>.
            </RF>
          )}
        </T>
        <T>
          {isEnglish && (
            <RF>
              TypoCop loads all resources from the domain typocop.com. Your IP
              address is therefore not exposed to anyone but{" "}
            </RF>
          )}
          {isSwedish && (
            <RF>
              TypoCop hämtar alla resurser från domänen typocop.com. Din
              IP-adress exponeras därför inte för någon annan än{" "}
            </RF>
          )}
          <a href="https://www.netlify.com/">Netlify</a>.
        </T>
      </div>
    );
  }
}
