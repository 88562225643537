import React from 'react'
import { Button } from '@material-ui/core'
import buyMeACoffeeIcon from '../static/buy-me-a-coffee.svg'
import paypalIcon from '../static/paypal.svg'

const style = {
  btnImgBMC: {
    height: 20,
    marginRight: 4,
  },
  btnImgPP: {
    height: 20,
  },
  btnBMC: {
    backgroundColor: '#FF813F',
    color: '#FFF',
  },
  btnPP: {
    backgroundColor: '#FFF',
  },
}

export class TCButtonBuyMeACoffee extends React.Component {
  render() {
    return (
      <TCButton
        style={style.btnBMC}
        variant="outlined"
        href="https://www.buymeacoffee.com/codertom"
      >
        <img
          style={style.btnImgBMC}
          src={buyMeACoffeeIcon}
          alt="Buy me a coffee logo"
        />
        Buy me a coffee
      </TCButton>
    )
  }
}

export class TCButtonPayPal extends React.Component {
  render() {
    return (
      <TCButton
        style={style.btnPP}
        variant="outlined"
        href="https://paypal.me/codertom"
      >
        <img src={paypalIcon} style={style.btnImgPP} alt="PayPal logo" />
      </TCButton>
    )
  }
}

export default class TCButton extends React.Component {
  render() {
    const { children, variant, ...others } = this.props
    const validVariants = {
      text: 'text',
      outlined: 'outlined',
      standard: 'contained',
    }
    const muiVariant =
      variant in validVariants ? validVariants[variant] : validVariants.standard
    return (
      <Button size="small" variant={muiVariant} color="primary" {...others}>
        {children}
      </Button>
    )
  }
}
