import React from "react";
import { Typography } from "@material-ui/core";

export default class TCNumber extends React.Component {
  constructor(props) {
    super(props);
    const textShadow = (color) => {
      return `-1px 0 1px ${color}, 0 1px 1px ${color}, 1px 0 1px ${color}, 0 -1px 1px ${color}`;
    };

    this.digits = {
      countdownNormal: {
        color: "#bbb", // theme blue: 3f51b5
        textShadow: textShadow("black"),
        fontSize: "34px",
        margin: "0px",
        padding: "0px",
        lineHeight: "1",
      },
      countdownDanger: {
        color: "red",
        textShadow: textShadow("black"),
        fontSize: "34px",
        margin: "0px",
        padding: "0px",
        lineHeight: "1",
      },
      scoreNormal: {
        color: "#FFD700",
        textShadow: textShadow("black"),
        fontSize: "34px",
        margin: "0px",
        padding: "0px",
        lineHeight: "1",
      },
    };
  }

  render() {
    const { number, align, variant } = this.props;
    if (isNaN(parseInt(number, 10))) {
      return null;
    }
    const style = this.digits[variant];
    const n = String(number).trim();
    return (
      <Typography align={align} style={style}>
        {n}
      </Typography>
    );
  }
}
