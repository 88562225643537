import React from "react";
import { Button, Typography, Dialog } from "@material-ui/core";
import TCButton from "./TCButton";
import lockIcon from "../static/lock.svg";
import { themeLevelsGet } from "../helpers/theme";
import { Style } from "./Common";

const buttonStyle = {
  padding: 0,
};
const dialogStyle = {
  padding: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
};
const easyStyle = {
  backgroundColor: Style.levels.easy.color,
};
const mediumStyle = {
  backgroundColor: Style.levels.medium.color,
};
const hardStyle = {
  backgroundColor: Style.levels.hard.color,
};

const style = {
  box: {
    width: 95,
    height: 90,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttoneasy: {
    ...easyStyle,
    ...buttonStyle,
  },
  buttonmedium: {
    ...mediumStyle,
    ...buttonStyle,
  },
  buttonhard: {
    ...hardStyle,
    ...buttonStyle,
  },
  dialogeasy: {
    ...easyStyle,
    ...dialogStyle,
  },
  dialogmedium: {
    ...mediumStyle,
    ...dialogStyle,
  },
  dialoghard: {
    ...hardStyle,
    ...dialogStyle,
  },
  trophies: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    margin: "0px 0px 16px 0px",
  },
};

class PreGameInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: this.props.open };
  }
  onPlay = () => {
    this.close();
    if (this.props.onPlay) {
      this.props.onPlay(this.props.level.id);
    }
  };
  close = () => {
    this.setState({ open: false });
  };
  onClose = () => {
    this.close();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { $$, language, themeComponent, level } = this.props;
    const { open } = this.state;
    const Theme = themeComponent;
    const languageLabel = $$(language).toUpperCase();
    return (
      <Dialog open={open} onClose={this.onClose}>
        <div style={style[`dialog${level.id}`]}>
          <Typography variant="h3">
            {`${languageLabel} - ${level.label}`}
          </Typography>
          {level.unlocked && level.highScore >= 0 && (
            <div
              style={{
                width: "100%",
                padding: "10px 0 10px 0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                minHeight: 150,
              }}
            >
              <Typography align="center">{$$("Your rank is")}</Typography>
              <Typography style={{ fontWeight: "bold" }} align="center">
                <Theme
                  $$={$$}
                  language={language}
                  score={level.highScore}
                  output="text"
                />
              </Typography>
              <Theme
                $$={$$}
                language={language}
                score={level.highScore}
                imageStyle={{ height: 80 }}
              />
              <Typography align="center">{`${level.highScore} points`}</Typography>
            </div>
          )}
          <div
            style={{
              width: "100%",
              padding: "10px 0 10px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              minHeight: 100,
            }}
          >
            {level.unlocked && (
              <React.Fragment>
                <TCButton
                  style={{ marginBottom: 4 }}
                  onClick={this.onPlay}
                  autoFocus
                >
                  {$$("PLAY")}
                </TCButton>
                <div>
                  <Typography variant="caption" align="center">
                    {$$("You have 30 seconds to find typos.")}
                  </Typography>
                  <Typography variant="caption" align="center">
                    {$$("Good luck!")}
                  </Typography>
                </div>
              </React.Fragment>
            )}
            {!level.unlocked && (
              <React.Fragment>
                <img
                  src={lockIcon}
                  alt={$$("Locked level icon")}
                  style={{ width: 32 }}
                />
                <Typography align="center">{$$("LOCKED")}</Typography>
                <Typography align="center">
                  {$$(
                    "Score {0}+ points on {1} to unlock.",
                    level.unlockScore,
                    $$(level.previous.id.toUpperCase())
                  )}
                </Typography>
              </React.Fragment>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}

export default class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pregameinfo: null };
  }
  onClick = (level) => {
    const { language, onPlay, themeComponent } = this.props;
    const onClose = () => this.setState({ pregameinfo: null });
    const pregameinfo = {
      language,
      onClose,
      onPlay,
      themeComponent,
      level,
    };
    this.setState({ pregameinfo });
  };
  render() {
    const { $$, language, themeComponent } = this.props;
    const Theme = themeComponent;
    const { pregameinfo } = this.state;
    const levels = themeLevelsGet(Theme.id, language);

    return (
      <div style={style.trophies}>
        {levels.map((level, index) => {
          return (
            <div key={`trophies-${index}`}>
              <Button
                variant="outlined"
                style={style[`button${level.id}`]}
                onClick={() => this.onClick(level)}
              >
                <div style={style.box}>
                  {level.label}
                  {level.unlocked && level.highScore >= 0 && (
                    <React.Fragment>
                      <Theme
                        $$={$$}
                        language={language}
                        score={level.highScore}
                        imageStyle={{ height: 46 }}
                      />
                      <Typography variant="caption">
                        {level.highScore}
                      </Typography>
                    </React.Fragment>
                  )}
                  {level.unlocked && level.highScore < 0 && (
                    <React.Fragment>
                      <Typography variant="caption">
                        {$$("Never played")}
                      </Typography>
                      <Typography variant="caption">
                        {$$("NO POINTS")}
                      </Typography>
                    </React.Fragment>
                  )}
                  {!level.unlocked && (
                    <React.Fragment>
                      <img
                        src={lockIcon}
                        alt={$$("Locked level icon")}
                        style={{ width: 32 }}
                      />
                      <Typography variant="caption">{$$("LOCKED")}</Typography>
                    </React.Fragment>
                  )}
                </div>
              </Button>
            </div>
          );
        })}
        {pregameinfo !== null && (
          <PreGameInfo
            $$={$$}
            open={true}
            language={pregameinfo.language}
            onClose={pregameinfo.onClose}
            onPlay={pregameinfo.onPlay}
            themeComponent={pregameinfo.themeComponent}
            level={pregameinfo.level}
          />
        )}
      </div>
    );
  }
}
