import React from "react";
import { Typography } from "@material-ui/core";
import copImage from "../static/cop.png";

export default function TCHeader({ $$, children }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img src={copImage} style={{ width: 100 }} alt={$$("TypoCop")} />
      <Typography style={style.gameTitle}>{$$("TYPOCOP")}</Typography>
      {children && (
        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
          {children}
        </Typography>
      )}
    </div>
  );
}

const style = {
  gameTitle: {
    color: "#3f51b5",
    fontWeight: "bold",
    fontSize: "200%",
  },
};
