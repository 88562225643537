import flagSweden from "../static/flag-sweden.png";
import flagUSA from "../static/flag-us.png";

const langInfo = [
  {
    id: "swedish",
    name: "Swedish",
    flag: {
      src: flagSweden,
      alt: "Swedish flag",
    },
    urlPart: "/se",
  },
  {
    id: "english",
    name: "English",
    flag: {
      src: flagUSA,
      alt: "American flag",
    },
    urlPart: "/",
  },
];

export const langInfoGet = (language) => {
  return langInfo.find((li) => li.id === language);
};

export const $$ = (language) => (txt, ...rest) => {
  let translatedTxt = null;
  if (language === "swedish") {
    translatedTxt = $$Swedish(txt);
  } else {
    translatedTxt = txt;
  }
  return translatedTxt.replace(/{(\d+)}/g, (match, number) => rest[number]);
};

const $$Swedish = (txt) => {
  const t = {};
  t["1. Find the typo."] = "1. Hitta skrivfelet.";
  t["2. Tap/Click on the typo."] = "2. Tryck/Klicka på skrivfelet.";
  t["3. Repeat 1-2 until the clock runs out."] =
    "3. Repetera 1-2 tills tiden runnit ut.";
  t["All rights reserved"] = "Alla rättigheter reserverade";
  t["ABOUT"] = "OM";
  t["American English"] = "Amerikansk engelska";
  t["Are you sure you want to delete all your high scores?"] =
    "Vill du verkligen radera alla dina rekord?";
  t["Average"] = "Genomsnitt";
  t["background image"] = "bakgrundsbild";
  t["CANCEL"] = "AVBRYT";
  t["CHALLENGE"] = "UTMANING";
  t["CHALLENGES"] = "UTMANINGAR";
  t["Change font size"] = "Byt fontstorlek";
  t["Change the font size of the sentences containing typos."] =
    "Byt fontstorleken på meningarna med skrivfel.";
  t["Click to play"] = "Klicka för att börja";
  t["CLOSE"] = "STÄNG";
  t["Contact"] = "Kontakt";
  t["Created by"] = "Skapat av";
  t["Credit to"] = "Beröm till";
  t["Delete high scores"] = "Radera rekord";
  t["Delete"] = "Radera";
  t["Do you want to know more? Read on."] = "Vill du veta mer? Läs vidare.";
  t["EASY"] = "LÄTT";
  t["easy"] = "lätt";
  t["EXIT"] = "EXIT";
  t["Failed to load dictionary."] = "Misslyckades ladda ordlista";
  t["First, thank you for playing TypoCop!"] =
    "Först och främst, tack för att du spelar TypoCop!";
  t["font Roboto"] = "font Roboto";
  t["for"] = "för";
  t["for questions/feedback on TypoCop."] =
    "för frågor/återkoppling om TypoCop.";
  t["for the most up-to-date contact information."] =
    "för de mest aktuella kontaktuppgifterna.";
  t['"Free for commercial use"'] = '"Free for commercial use"';
  t["Game report"] = "Spelrapport";
  t["GAME REPORT"] = "SPELRAPPORT";
  t["Generic Resources"] = "Generiska resurser";
  t["Good luck!"] = "Lycka till!";
  t["HALLOWEEN"] = "HALLOWEEN";
  t["HARD"] = "SVÅR";
  t["hard"] = "svår";
  t["HIGH SCORE"] = "REKORD";
  t["HOW TO PLAY"] = "SPELREGLER";
  t[
    "I want to spend more time developing TypoCop and other free games and tools, but I can't without your support."
  ] =
    "Jag vill ägna mer tid åt att utveckla TypoCop och andra (gratis) spel och verktyg, men det går inte utan ditt stöd.";
  t["I'll ask again later"] = "Jag frågar igen senare";
  t["I'm on Twitter:"] = "Jag finns på Twitter:";
  t["image marking a mistake"] = "bild som markerar ett misstag";
  t["image for rank {0} ({1}+ points)"] = "bild för rank {0} ({1}+ poäng)";
  t["image of a blushing emoji"] = "bild på en rodnande emoji";
  t['image of a cartoon cop looking all "TypoCop-ish"'] =
    'bild på en tecknad polis som ser rätt "TypoCop-aktig" ut';
  t["image of a heart-eyed emoji"] = "bild på en emoji med hjärtan som ögon";
  t["image of a lock"] = "bild på ett lås";
  t["image of the American flag"] = "bild på den amerikanska flaggan";
  t["image of the Swedish flag"] = "bild på den svenska flaggan";
  t["LEGAL"] = "LEGALT";
  t["Legal"] = "Legalt";
  t["Loading"] = "Laddar";
  t["LOCKED"] = "LÅST";
  t["Locked level icon"] = "Ikon för låst nivå";
  t["Maybe later emoji"] = "Kanske senare emoji";
  t["MEDIUM"] = "MEDEL";
  t["medium"] = "medel";
  t["Miss"] = "Miss";
  t["Miss icon"] = "Ikon för misstag";
  t["modified"] = "modifierad";
  t["Never played"] = "Aldrig spelat";
  t["NEW HIGH SCORE!"] = "NYTT REKORD!";
  t["NO POINTS"] = "INGA POÄNG";
  t["not modified"] = "inte modifierad";
  t["Not right now"] = "Inte just nu";
  t["OK"] = "OK";
  t["PLAY"] = "SPELA";
  t["PLAY AGAIN"] = "SPELA IGEN";
  t["Play to get ranked"] = "Spela för att bli rankad.";
  t["Please, a moment of your time..."] = "Ursäkta att jag stör...";
  t["Please use"] = "Vänligen använd";
  t["point"] = "poäng";
  t["points"] = "poäng";
  t["PRIVACY"] = "INTEGRITET";
  t["Privacy"] = "Integritet";
  t["Really reset your high scores for the current challenge?"] =
    "Verkligen radera dina rekord för den aktuella utmaningen?";
  t["Really reset everything?"] = "Verkligen radera allt?";
  t["Regards,"] = "Hälsningar,";
  t["Release notes"] = "Förändringsprotokoll";
  t["RELOAD"] = "LADDA OM";
  t["Reload to update TypoCop:"] = "Ladda om för att uppdatera TypoCop";
  t["RESET"] = "RADERA";
  t["Reset"] = "Radera";
  t["Reset challenge high scores"] = "Radera utmaningens rekord";
  t["Reset everything"] = "Radera allt";
  t["Resources for Current Challenge"] = "Resurser för aktuell utmaning";
  t["Resources used in the current challenge."] =
    "Resurser som används i den aktuella utmaningen.";
  t["Resources that are not specific to the current challenge."] =
    "Resurser som inte hör till den aktuella utmaningen.";
  t["Round"] = "Runda";
  t["Score {0}+ points on {1} to unlock."] =
    "Ta {0}+ poäng på {1} för att låsa upp.";
  t["SETTINGS"] = "INSTÄLLNINGAR";
  t["So, if you want to and have the means:"] =
    "Så, om du vill och har möjlighet:";
  t["Swedish"] = "Svenska";
  t["swedish"] = "svenska";
  t["Thank you emoji"] = "Tack emoji";
  t["Thank you for your support!"] = "Tack för ditt stöd!";
  t["That's cool, no worries."] = "Det är lugnt, inga problem.";
  t["The Details"] = "Detaljerna";
  t["THE GAME"] = "SPELET";
  t["This site's TypoCop licence has expired. :("] =
    "Denna sajts TypoCop licens har gått ut :(";
  t["This is what you do:"] = "Gör så här:";
  t["Time (ms)"] = "Tid (ms)";
  t["Time (milliseconds) you spent compared to the average:"] =
    "Tid (millisekunder) du spenderade jämfört med genomsnittet:";
  t["TypoCop"] = "TypoCop";
  t["TYPOCOP"] = "TYPOCOP";
  t[
    "TypoCop failed to start, which usually means a new version is available."
  ] =
    "TypoCop kunde inte starta, vilket vanligtvis betyder att en ny version är tillgänglig.";
  t["TypoCop logo"] = "TypoCop logo";
  t["TypoCop rates your performance:"] = "TypoCop rankar din prestation";
  t["TypoCop version {0}"] = "TypoCop version {0}";
  t[
    "TypoCop will forget all your high scores for the current challenge: {0}."
  ] =
    "TypoCop kommer att radera alla dina rekord för den aktuella utmaningen: {0}.";
  t["TypoCop will forget everything about you, including your high scores."] =
    "TypoCop kommer att radera all information om dig, inklusive dina rekord.";
  t[
    "TypoCop will forget everything about you, including your high scores, whether or not you are a supporter, etc. Everything. Are you SURE you want to do this?"
  ] =
    "TypoCop kommer att radera all information om dig, inklusive dina rekord, huruvida du är en supporter eller inte, osv. Allt. Är du SÄKER på att du vill göra det här?";
  t["TypoCop will forget your high scores for the current challenge."] =
    "TypoCop kommer att radera dina rekord för den aktuella utmaningen.";
  t["under the license"] = "under licensen";
  t["Visit my website"] = "Besök min hemsida";
  t["Welcome to TypoCop!"] = "Välkommen till TypoCop!";
  t["YES"] = "JA";
  t["You"] = "Du";
  t["You did not beat your high score."] = "Du slog inte ditt rekord.";
  t["You have 30 seconds to find typos."] =
    "Du har 30 sekunder att hitta skrivfel.";
  t["You played {0} rounds."] = "Du spelade {0} rundor.";
  t["YOU UNLOCKED THE NEXT LEVEL!"] = "DU LÅSTE UPP NÄSTA NIVÅ!";
  t["Your rank is"] = "Din ranking är";
  t["Your reply:"] = "Ditt svar:";
  t["You've received my support"] = "Du har fått mitt stöd";
  return txt in t ? t[txt] : txt;
};
