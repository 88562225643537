import React from "react";
import copImage from "../static/cop.png";
import TCNumber from "./TCNumber";
import { Typography } from "@material-ui/core";

export default class Bar extends React.Component {
  render() {
    const { score, countdown, title, challengeId } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.top}>
          <div style={styles.spacer}>
            {!isNaN(parseInt(score, 10)) && (
              <TCNumber align="left" variant="scoreNormal" number={score} />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              src={copImage}
              style={{ width: 36, height: 36 }}
              alt="TypoCop"
            />
          </div>
          <div style={styles.spacer}>{countdown ? countdown : ""}</div>
        </div>
        <div style={styles.bottom}>
          {title && <Typography variant="caption">{title}</Typography>}
          {challengeId && (
            <Typography variant="caption">{challengeId}</Typography>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: 0,
    margin: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  top: {
    padding: 2,
    margin: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  spacer: {
    width: 100,
  },
};
