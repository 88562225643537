import React from "react";
import { Typography } from "@material-ui/core";
import Screen from "./Screen";
import copImage from "../static/cop.png";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class TCLoading extends React.Component {
  render() {
    const { $$ } = this.props;
    return (
      <Screen>
        <div style={style.columnSpread}>
          <img src={copImage} alt="TypoCop" />
          <Typography align="center">{$$("Loading")}</Typography>
          <LinearProgress style={{ width: 200 }} />
        </div>
      </Screen>
    );
  }
}

const style = {
  columnSpread: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "50vh",
  },
};
