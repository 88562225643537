const base = {
  columnSpread: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
};
export const styles = {
  columnSpread: base.columnSpread,
  columnSpreadFill: {
    ...base.columnSpread,
    height: "95vh",
  },
  rowSpread: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
};
