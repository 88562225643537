import React from "react";
import { Link } from "react-router-dom";
import { langInfoGet } from "../helpers/lang";

export default function LanguageChoice({ languages, selectedLanguageId, $$ }) {
  return (
    <div
      style={{
        width: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        margin: "0px 0px 16px 0px",
      }}
    >
      {languages.map((lang) => {
        const langInfo = langInfoGet(lang);
        return (
          <Link
            key={`flag-${lang}`}
            to={langInfo.urlPart}
            style={{ outline: "none" }}
          >
            <img
              src={langInfo.flag.src}
              style={
                selectedLanguageId === langInfo.id
                  ? style.selectedFlag
                  : style.notSelectedFlag
              }
              alt={$$(langInfo.name)}
            />
          </Link>
        );
      })}
    </div>
  );
}

const style = {
  selectedFlag: {
    width: 32,
    borderBottom: "4px solid black",
    margin: 0,
    marginRight: 10,
    marginLeft: 10,
    padding: "0px 0px 2px 0px",
  },
  notSelectedFlag: {
    width: 32,
    margin: 0,
    marginRight: 10,
    marginLeft: 10,
    padding: "0px 0px 2px 0px",
  },
};
