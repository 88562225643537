export const KEYBOARD_SE = ['qwertyuiopå', 'asdfghjklöä', 'zxcvbnm    ']
export const KEYBOARD_EN = ['qwertyuiop', 'asdfghjkl ', 'zxcvbnm    ']

export const keyboardNeighborsSwedish = letter => {
  return keyboardNeighbors(letter, KEYBOARD_SE)
}

export const keyboardNeighborsEnglish = letter => {
  return keyboardNeighbors(letter, KEYBOARD_EN)
}

export const keyboardNeighbors = (letter, keyboard) => {
  // all letter neighbors on keyboard, i.e. distance=1
  // keyboard e.g. KEYBOARD_SE
  const rowLength = keyboard.length
  const colLength = keyboard[0].length
  const neighbors = (rownr, colnr) => {
    const offsets = [[-1, 0], [-1, 1], [0, -1], [0, 1], [1, -1], [1, 0]]
    const relative = offsets.map(offset => ({
      row: rownr + offset[0],
      col: colnr + offset[1],
    }))
    const coord = relative.filter(
      c => c.row >= 0 && c.row < rowLength && c.col >= 0 && c.col < colLength
    )
    const n = coord.map(c => keyboard[c.row][c.col])
    return n
  }

  const r = keyboard.findIndex(r => r.includes(letter))
  if (r < 0) {
    return [] // happens if you e.g. ask for neighbors of 'å' on english keyboard
  }
  const c = keyboard[r].indexOf(letter)
  const res = neighbors(r, c).filter(n => n !== ' ' && n !== letter)
  return res
}
