import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TCButton from "./TCButton";
import copImage from "../static/cop.png";
import { Toolbar, AppBar, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

class TCDialog extends React.Component {
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
  render() {
    const { $$, fullScreen, open, title, children } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={open}
        onClose={this.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <img
                src={copImage}
                style={{ width: 32, height: 32 }}
                alt="TypoCop"
              />
              <Typography
                id="responsive-dialog-title"
                variant="h3"
                color="inherit"
              >
                {title.toUpperCase()}
              </Typography>
            </div>
            <IconButton
              color="inherit"
              onClick={this.onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <div style={style.actions}>
            <TCButton onClick={this.onClose}>{$$("CLOSE")}</TCButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const style = {
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
};

export default withMobileDialog()(TCDialog);
