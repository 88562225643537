import React from 'react'
import TCDialog from './TCDialog'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
} from '@material-ui/core'
import TCButton from './TCButton'
import {
  dbHighScoresClear,
  dbReset,
  dbUserConfigGet,
  dbUserConfigSet,
} from '../helpers/db'
import { themeNameGet } from '../helpers/theme'

class Ask extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: this.props.open }
  }
  onYes = () => {
    this.close()
    if (this.props.onYes) {
      this.props.onYes()
    }
  }
  onNo = () => {
    this.close()
    if (this.props.onNo) {
      this.props.onNo()
    }
  }
  close = () => {
    this.setState({ open: false })
  }
  onClose = () => {
    this.close()
  }
  render() {
    const { question, description, $$ } = this.props
    const { open } = this.state
    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <TCButton autoFocus onClick={this.onNo}>
            {$$('CANCEL')}
          </TCButton>
          <TCButton onClick={this.onYes}>{$$('YES')}</TCButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default class Settings extends React.Component {
  constructor(props) {
    super(props)
    const userConfig = dbUserConfigGet()
    this.state = { ask: null, userConfig }
  }
  resetHighScores = () => {
    dbHighScoresClear(this.props.themeId)
  }
  resetEverything = () => {
    dbReset()
  }
  resetHighScoresAsk = () => {
    const { $$, language } = this.props
    const ask = {
      question: $$('Really reset your high scores for the current challenge?'),
      description: $$(
        'TypoCop will forget all your high scores for the current challenge: {0}.',
        themeNameGet(this.props.themeId, language)
      ),
      onYes: this.resetHighScores,
      onNo: this.askClose,
      onClose: this.askClose,
    }
    this.setState({ ask })
  }
  resetEverythingAsk = () => {
    const { $$ } = this.props
    const ask = {
      question: $$('Really reset everything?'),
      description: $$(
        'TypoCop will forget everything about you, including your high scores, whether or not you are a supporter, etc. Everything. Are you SURE you want to do this?'
      ),
      onYes: this.resetEverything,
      onNo: this.askClose,
      onClose: this.askClose,
    }
    this.setState({ ask })
  }
  askClose = () => {
    this.setState({ ask: null })
  }
  onGameFontChange = e => {
    const { userConfig } = this.state
    const fontOffset = e.target.value
    userConfig.gameFontSizeOffset = fontOffset
    dbUserConfigSet(userConfig)
    this.setState({ userConfig })
  }
  render() {
    const { onClose, open, $$ } = this.props
    const { ask, userConfig } = this.state
    return (
      <TCDialog title={$$('SETTINGS')} open={open} $$={$$} onClose={onClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          {/* GAME */}
          <div style={style.settingSection}>
            <Typography style={style.settingSectionTitle}>
              {$$('THE GAME')}
            </Typography>
            <div style={style.settingItem}>
              <div style={style.settingItemInfo}>
                <Typography>{$$('Change font size')}</Typography>
                <Typography variant="caption">
                  {$$(
                    'Change the font size of the sentences containing typos.'
                  )}
                </Typography>
              </div>
              <div style={style.settingItemControls}>
                <Select
                  value={userConfig.gameFontSizeOffset}
                  onChange={this.onGameFontChange}
                >
                  {[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5].map(val => (
                    <MenuItem key={`game-font-size-${val}`} value={val}>
                      <Typography style={{ fontSize: 18 + val }}>
                        {val === 0 ? $$('±0') : val > 0 ? `+${val}` : val}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          {/* GAME END */}

          {/* RESET */}
          <div style={style.settingSection}>
            <Typography style={style.settingSectionTitle}>
              {$$('RESET')}
            </Typography>
            <div style={style.settingItem}>
              <div style={style.settingItemInfo}>
                <Typography>{$$('Reset challenge high scores')}</Typography>
                <Typography variant="caption">
                  {$$(
                    'TypoCop will forget your high scores for the current challenge.'
                  )}
                </Typography>
              </div>
              <div style={style.settingItemControls}>
                <TCButton onClick={this.resetHighScoresAsk}>
                  {$$('Reset')}
                </TCButton>
              </div>
            </div>
            <div style={style.settingItem}>
              <div style={style.settingItemInfo}>
                <Typography>{$$('Reset everything')}</Typography>
                <Typography variant="caption">
                  {$$(
                    'TypoCop will forget everything about you, including your high scores.'
                  )}
                </Typography>
              </div>
              <div style={style.settingItemControls}>
                <TCButton onClick={this.resetEverythingAsk}>
                  {$$('Reset')}
                </TCButton>
              </div>
            </div>
          </div>
          {/* RESET END */}

          {ask !== null && (
            <Ask
              open={true}
              onClose={ask.onClose}
              onYes={ask.onYes}
              onNo={ask.onNo}
              question={ask.question}
              description={ask.description}
              $$={$$}
            />
          )}
        </div>
      </TCDialog>
    )
  }
}

const style = {
  settingSection: { width: '100%' },
  settingItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 4,
    backgroundColor: '#ddd',
    marginBottom: 8,
  },
  settingSectionTitle: { padding: '20px 0 10px 0' },
  settingItemInfo: { padding: 2 },
  settingItemControls: { padding: 2 },
}
