// bloomfilter functions

// base91 has more efficient encoding space-wise
// https://github.com/Equim-chan/base91/blob/master/package.json

import bf from "bloomfilter";

export const bfLoad = (language, doOnSuccess, doOnError) => {
  if (language === "swedish") {
    bfLoadSwedish(doOnSuccess, doOnError);
    return true;
  } else if (language === "english") {
    bfLoadEnglish(doOnSuccess, doOnError);
    return true;
  } else {
    return false;
  }
};

const bfCreate = (dictionarybuckets, nhashes) => {
  const buf = atob(dictionarybuckets);
  var buckets = [];
  for (var i = 0; i < buf.length / 4; i++) {
    const byte1 = buf.charCodeAt(4 * i);
    const byte2 = buf.charCodeAt(4 * i + 1);
    const byte3 = buf.charCodeAt(4 * i + 2);
    const byte4 = buf.charCodeAt(4 * i + 3);
    const val = (byte1 << 24) + (byte2 << 16) + (byte3 << 8) + byte4;
    buckets.push(val);
  }
  const bloom = new bf.BloomFilter(buckets, nhashes);
  return bloom;
};

const bfLoadSwedish = (doOnSuccess, doOnError) => {
  import(
    "../dictionaries/dictionary-se-bloomfilter-k6.json" /* webpackChunkName: "BFBSwedish" */
  )
    .then((dictionarybuckets) => {
      const bloom = bfCreate(dictionarybuckets.default, 6);
      doOnSuccess(bloom);
    })
    .catch((err) => {
      doOnError(err.message);
    });
};

const bfLoadEnglish = (doOnSuccess, doOnError) => {
  import(
    "../dictionaries/dictionary-us-and-gb-bloomfilter-k8.json" /* webpackChunkName: "BFBEnglish" */
  )
    .then((dictionarybuckets) => {
      const bloom = bfCreate(dictionarybuckets.default, 6);
      doOnSuccess(bloom);
    })
    .catch((err) => {
      doOnError(err.message);
    });
};
