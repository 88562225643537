import React from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import Bar from './Bar'

class Screen extends React.Component {
  render() {
    let { style, width, showTopBar } = this.props
    showTopBar = showTopBar === undefined ? false : showTopBar
    let styleWidth = '100%'
    if (isWidthUp(500, width)) {
      styleWidth = 500
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000',
        }}
      >
        <div
          style={{
            width: styleWidth,
            height: '100vh',
            overflow: 'hidden',
            backgroundColor: '#fff',
            ...style,
          }}
        >
          {showTopBar && <Bar />}
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withWidth()(Screen)
