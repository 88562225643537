import React from "react";
import Typography from "@material-ui/core/Typography";

export const Style = {
  levels: {
    easy: {
      color: "rgba(221,255,221,0.7)",
    },
    medium: {
      color: "rgba(221,221,255,0.7)",
    },
    hard: {
      color: "rgba(255,221,221,0.7)",
    },
  },
};

export function TCFooter({ $$ }) {
  return (
    <Typography variant="caption">
      {"© 2018-2020, 2023 "}
      <a href="https://tom10.net">tom10</a> {$$("All rights reserved")}{" "}
    </Typography>
  );
}
