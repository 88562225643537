import React from "react";
import fileconfig from "../config";
import TCError from "../components/TCError";
import { Typography, Box } from "@material-ui/core";
import { withRouter } from "react-router";
import { gameChallengeCreate } from "../helpers/game";
import { bfLoad } from "../helpers/bf";
import { themeLoad, themeLanguagesGet, themeNameGet } from "../helpers/theme";
import TCLoading from "../components/TCLoading";
import Screen from "../components/Screen";
import TCHeader from "../components/TCHeader";
import { styles } from "../helpers/styles";
import { TCFooter } from "../components/Common";
import PlainButton from "../components/PlainButton";
import { dbHighScoreGet, dbThemeGet } from "../helpers/db";
import TCButton from "../components/TCButton";
import LanguageChoice from "../components/LanguageChoice";

class ChallengeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      config: null,
      error: [],
      loadingBloomfilter: true,
      loadingTheme: true,
      level: null,
      seed: null,
      language: null,
      userMustReload: false,
    };
  }

  isExpired = () => {
    if (fileconfig.expiresOn === undefined) {
      return false;
    }
    const now = new Date();
    const expiresOn = new Date(fileconfig.expiresOn);
    return now >= expiresOn;
  };

  onError = (newError) => {
    let { error } = this.state;
    error = [...error, newError];
    this.safeSetState({ error });
  };

  safeSetState = (newState) => {
    // safely set state
    // async calls could finish after someone has unmounted the component (e.g. during tests),
    // and we must not call setState on unmounted components
    if (this.mounted) {
      this.setState(newState);
    }
  };

  onResourceLoadingError = (error) => {
    this.setState({ userMustReload: true });
  };

  componentDidMount() {
    this.mounted = true;
    const { $$ } = this.props;
    if (this.isExpired()) {
      this.onError($$("This site's TypoCop licence has expired. :("));
      return;
    }
    let { id } = this.props.match.params;

    // [0] version, 0=standard (the only one available)
    // [1] language: 0=english, 1=swedish
    // [2] theme, 0=spring-fever, 1=winterwonderland, 2=halloween
    // [3] level, 0=easy, 1=medium, 2=hard
    // [4-8] seed, 0 - 99'999
    // 999-999-999
    const idm = id.match(/^0([01])([012])-([012])([\d-]{6})$/);
    if (!idm) {
      this.onError($$("Not a valid game challenge. Retry or Go home."));
      return;
    }

    const params = idm.map((e) => parseInt(e.replace("-", "")));
    const language = ["english", "swedish"][params[1]];
    const theme = ["spring-fever", "winterwonderland", "halloween"][params[2]];
    const level = ["easy", "medium", "hard"][params[3]];
    const seed = params[4];
    this.safeSetState({ level, seed, language });

    const config = Object.assign({}, fileconfig);
    this.safeSetState({ config });
    this.props.saveConfig(config);

    const onBloomfilterLoadSuccess = (bloomfilter) => {
      this.bloomfilter = bloomfilter;
      this.safeSetState({ loadingBloomfilter: false });
      this.props.saveBloomfilter(bloomfilter);
    };
    const bfRequestOk = bfLoad(
      language,
      onBloomfilterLoadSuccess,
      this.onResourceLoadingError
    );
    if (!bfRequestOk) {
      this.onError($$("Failed to load dictionary."));
    }

    const onThemeLoadSuccess = (themeLoaded) => {
      this.Theme = themeLoaded.default;
      this.challenges = this.Theme.challengesGet()[language];
      this.safeSetState({ loadingTheme: false });
      this.props.saveTheme(this.Theme);
    };
    const themeRequestOk = themeLoad(
      theme,
      onThemeLoadSuccess,
      this.onResourceLoadingError
    );
    if (!themeRequestOk) {
      this.onError($$("Failed to load theme."));
    }
  }

  launchGame = (highScore) => () => {
    if (highScore > -1) {
      return;
    }
    const { history, makeUrl } = this.props;
    const { id } = this.props.match.params;
    const { level, seed, config } = this.state;
    history.push(makeUrl("game"), {
      challenge: gameChallengeCreate(
        config,
        this.challenges[level],
        level,
        this.Theme.id
      ),
      challengeId: id,
      seed,
    });
  };

  onExit = () => {
    const { history, makeUrl } = this.props;
    history.push(makeUrl(""));
  };

  render() {
    const {
      error,
      loadingBloomfilter,
      loadingTheme,
      userMustReload,
      level,
      language,
    } = this.state;
    const { $$ } = this.props;
    if (!this.mounted) {
      return null;
    }
    if (userMustReload) {
      const reloadError = [
        $$(
          "TypoCop failed to start, which usually means a new version is available."
        ),
        $$("Reload to update TypoCop:"),
      ];
      return (
        <TCError
          $$={$$}
          language={language}
          error={reloadError}
          showReloadButton={true}
        />
      );
    }
    if (error.length > 0) {
      return <TCError $$={$$} language={language} error={error} />;
    }
    if (loadingBloomfilter || loadingTheme) {
      return <TCLoading $$={$$} />;
    }
    const loadingDone = !loadingBloomfilter && !loadingTheme;
    if (!loadingDone) {
      return (
        <Screen style={this.Theme.style.HomeScreen}>
          <Typography>{$$("Loading")} ...</Typography>
        </Screen>
      );
    }

    const challengeId = this.props.match.params.id;
    const highScore = dbHighScoreGet(null, null, null, challengeId);
    const Theme = this.Theme;
    const themeName = dbThemeGet(Theme.id).name[language];
    const languages = themeLanguagesGet(this.Theme.id);
    return (
      <Screen style={this.Theme.style.HomeScreen}>
        <div style={styles.columnSpreadFill}>
          <TCHeader $$={$$}>{$$("ONE-TIME CHALLENGE")}</TCHeader>

          <Box style={styles.columnSpread}>
            <Box style={styles.columnSpread}>
              <PlainButton onClick={() => console.log("theme click")}>
                <Typography style={{ fontWeight: "bold" }}>
                  {themeNameGet(this.Theme.id, language).toUpperCase()}
                </Typography>
              </PlainButton>
              <LanguageChoice
                $$={$$}
                languages={languages}
                selectedLanguageId={language}
              ></LanguageChoice>
              <PlainButton>CHALLENGE SEED: 12345</PlainButton>
            </Box>

            <PlainButton onClick={this.launchGame(highScore)}>
              <div>
                {highScore <= 0 && (
                  <>
                    <Typography>{$$("PLAY")}</Typography>
                    <Typography variant="caption">
                      {$$("You have 30 seconds to find typos.")}
                    </Typography>
                  </>
                )}
                {highScore > -1 && (
                  <>
                    <Typography style={{ fontSize: "24px" }}>
                      {highScore} {highScore === 1 ? $$("point") : $$("points")}
                    </Typography>
                    <Typography style={{ fontSize: "24px" }}>
                      <Theme
                        $$={$$}
                        language={language}
                        score={highScore}
                        output="text"
                      />
                    </Typography>
                    <Theme
                      $$={$$}
                      language={language}
                      score={highScore}
                      imageStyle={{ height: 80 }}
                    />
                  </>
                )}
              </div>
            </PlainButton>
          </Box>
          <TCButton onClick={this.onExit}>{$$("EXIT")}</TCButton>
          <TCFooter $$={$$}></TCFooter>
        </div>
      </Screen>
    );
  }
}

export default withRouter(ChallengeScreen);
