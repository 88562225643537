import React from 'react'
import Typography from '@material-ui/core/Typography'
import { dbUserConfigGet } from '../helpers/db'

export default class Game extends React.Component {
  constructor(props) {
    super(props)
    this.state = { timeStart: null }
  }

  componentDidMount() {
    // called once after mount (render) so good place to start timer
    const timeStart = Date.now()
    this.setState({ timeStart })
  }

  onWin = wordIndex => e => {
    if (this.props.onWin) {
      this.props.onWin(wordIndex)
    }
  }

  onMiss = wordIndex => e => {
    if (this.props.onMiss) {
      this.props.onMiss(wordIndex)
    }
  }

  words = () => {
    return this.props.s.match(/\S+/g) || []
  }

  typoMarker = () => {
    return this.props.typoMarker
  }

  render() {
    const fontSize = 19 + dbUserConfigGet().gameFontSizeOffset
    const { typoMarker } = this.props
    return (
      <div style={styles.container}>
        <div style={styles.wordcontainer}>
          <Typography>
            {this.words().map((word, index) => {
              if (word === '\\n') {
                return <br key={`br-${index}`} />
              } else if (word.includes(typoMarker)) {
                const typoword = word.replace(typoMarker, '')
                return (
                  <span
                    key={index}
                    onClick={this.onWin(index)}
                    style={{ fontSize }}
                  >
                    {`${typoword} `}
                  </span>
                )
              } else {
                return (
                  <span
                    key={index}
                    onClick={this.onMiss(index)}
                    style={{ fontSize }}
                  >
                    {`${word} `}
                  </span>
                )
              }
            })}
          </Typography>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    padding: '20px 40px 20px 40px',
  },
  wordcontainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  },
}
