// use localStorage for now
// localStorage is unique per ORIGIN, i.e.: "protocol://host:port" (e.g. https://typocop.com)
// https://www.w3.org/TR/webstorage/

// consider IndexedDB
// Like most web storage solutions,
// IndexedDB follows a same-origin policy.
// So while you can access stored data within a domain,
// you cannot access data across different domains.
// https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Basic_Concepts_Behind_IndexedDB

const levelsDefault = () => {
  const mediumUnlockScore = 20; // score this many points on easy to unlock medium
  const hardUnlockScore = 20; // ... equivalent
  const easy = {
    id: "easy",
    name: { english: "Easy", swedish: "Lätt" },
    unlockScore: -1,
    isUnlocked: (_) => true,
  };
  const medium = {
    id: "medium",
    name: { english: "Medium", swedish: "Medel" },
    unlockScore: mediumUnlockScore,
    isUnlocked: (score) => score >= mediumUnlockScore,
  };
  const hard = {
    id: "hard",
    name: { english: "Hard", swedish: "Svår" },
    unlockScore: hardUnlockScore,
    isUnlocked: (score) => score >= hardUnlockScore,
  };
  easy.next = medium;
  easy.previous = null;
  medium.next = hard;
  medium.previous = easy;
  hard.next = null;
  hard.previous = medium;
  return [easy, medium, hard];
};

const THEME_DEFAULT = "spring-fever";

const THEMES = [
  {
    id: "halloween",
    name: { english: "Halloween", swedish: "Halloween" },
    languages: ["english", "swedish"],
    levels: levelsDefault(),
    color: "rgb(247, 95, 28)",
  },
  {
    id: "ww",
    name: { english: "Winter Wonderland", swedish: "Winter Wonderland" },
    languages: ["english", "swedish"],
    levels: levelsDefault(),
    color: "rgb(160, 230, 255)",
  },
  {
    id: "spring-fever",
    name: { english: "Spring Fever", swedish: "Vårkänslor" },
    languages: ["english", "swedish"],
    levels: levelsDefault(),
    color: "rgb(180, 249, 165)",
  },
  // add new themes above this line
];

export const dbThemeIdsGet = () => {
  return THEMES.map((t) => t.id);
};

export const dbThemeGet = (theme) => {
  return THEMES.find((t) => t.id === theme);
};

const dbThemeLevelProgress = (theme, level, score) => {
  // Is the score on this level enough to unlock the next level?
  const themeObj = dbThemeGet(theme);
  const levelObj = themeObj.levels.find((l) => l.id === level);
  return levelObj.next && levelObj.next.isUnlocked(score);
};

const dbSet = (key, value) => {
  // const valueToStore = window.btoa(JSON.stringify(value))
  const valueToStore = JSON.stringify(value);
  localStorage.setItem(key, valueToStore);
};

const dbGet = (key) => {
  const value = localStorage.getItem(key); // returns null if key is not set
  // return value === null ? null : JSON.parse(window.atob(value))
  return value === null || value.length === 0 ? null : JSON.parse(value);
};

export const dbReset = () => {
  localStorage.clear();
};

const dbHighScoreKey = (theme, language, level, challengeId = null) => {
  const key = challengeId
    ? `tc_high_score__challenge_${challengeId}`
    : `tc_high_score_${theme}_${language}_${level}`;
  return key;
};
const dbGamesPlayedCountKey = () => {
  return "tc_games_played_count";
};
const dbSupportLaterCountKey = () => {
  return "tc_support_later_count";
};
const dbSupportGivenKey = () => {
  return "tc_support_given";
};
const dbUserConfigKey = () => {
  return "tc_config";
};
const dbThemeActiveIdKey = () => {
  return "tc_theme_active_id";
};
const dbChallengeIndexKey = () => {
  return "tc_challenge_index";
};

export const dbChallengeIndexGet = () => {
  const key = dbChallengeIndexKey();
  const value = dbGet(key);
  return value !== null ? value : [];
};

export const dbChallengeIndexAdd = (challengeId) => {
  const index = dbChallengeIndexGet();
  console.log(index);
  if (!index.includes(challengeId)) {
    index.push(challengeId);
    const key = dbChallengeIndexKey();
    dbSet(key, index);
  }
};

export const dbThemeActiveIdGet = () => {
  const key = dbThemeActiveIdKey();
  const value = dbGet(key);
  return value !== null && value.length > 0 ? value : THEME_DEFAULT;
};

export const dbThemeActiveIdSet = (theme) => {
  const key = dbThemeActiveIdKey();
  dbSet(key, theme);
};

export const dbUserConfigGet = () => {
  const key = dbUserConfigKey();
  const defaultConfig = {
    gameFontSizeOffset: 0,
  };
  const storedConfig = dbGet(key);
  if (storedConfig === null) {
    return defaultConfig;
  }
  const config = { ...defaultConfig, ...storedConfig };
  return config;
};

export const dbUserConfigSet = (config) => {
  const key = dbUserConfigKey();
  dbSet(key, config);
};

export const dbSupportGivenGet = () => {
  const key = dbSupportGivenKey();
  const value = dbGet(key);
  return value === true;
};

export const dbSupportGivenSet = (isGiven) => {
  const key = dbSupportGivenKey();
  const value = isGiven === true;
  dbSet(key, value);
};

export const dbSupportLaterCountGet = () => {
  const key = dbSupportLaterCountKey();
  const value = dbGet(key);
  return value === null ? 0 : value;
};

export const dbSupportLaterCountIncrement = () => {
  const value = dbSupportLaterCountGet() + 1;
  const key = dbSupportLaterCountKey();
  dbSet(key, value);
};

export const dbGamesPlayedCountGet = () => {
  const key = dbGamesPlayedCountKey();
  const value = dbGet(key);
  return value === null ? 0 : value;
};

export const dbGamesPlayedCountIncrement = () => {
  const value = dbGamesPlayedCountGet() + 1;
  const key = dbGamesPlayedCountKey();
  dbSet(key, value);
};

export const dbHighScoreGet = (theme, language, level, challengeId = null) => {
  const key = dbHighScoreKey(theme, language, level, challengeId);
  const value = dbGet(key);
  return value === null ? -1 : value;
};

export const dbHighScoresClear = (theme) => {
  const themeObj = dbThemeGet(theme);
  for (const language of themeObj.languages) {
    for (const level of themeObj.levels) {
      const hsKey = dbHighScoreKey(theme, language, level.id);
      localStorage.removeItem(hsKey);
    }
  }
};

const dbHighScoreSet = (theme, language, level, score, challengeId = null) => {
  const key = dbHighScoreKey(theme, language, level, challengeId);
  dbSet(key, score);
};

export const dbHighScoreUpdate = (
  theme,
  language,
  level,
  score,
  challengeId = null
) => {
  const highScore = dbHighScoreGet(theme, language, level, challengeId);
  let newHighScore = false;
  if (score > highScore) {
    dbHighScoreSet(theme, language, level, score, challengeId);
    newHighScore = true;
  }
  const newLevelUnlocked =
    !dbThemeLevelProgress(theme, level, highScore) &&
    dbThemeLevelProgress(theme, level, score);
  return [newHighScore, newLevelUnlocked];
};
