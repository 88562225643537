let seed = null;

export const randomSeedReset = (newSeed = null) => {
  seed = newSeed;
};

const randomInternal = () => {
  if (seed === null) {
    return Math.random();
  }
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

export const random = (minInclusive, maxExclusive) => {
  const length = maxExclusive - minInclusive;
  return minInclusive + Math.floor(randomInternal() * length);
};

export const randomBool = (trueOdds) => {
  // return true with 'trueOdds' % chance [0..100], e.g. 80 for 80 %
  return random(1, 101) <= trueOdds;
};

export const randomPick = (arr) => {
  if (arr.length === 0) {
    return arr;
  }
  return arr[randomPickIndex(arr)];
};

export const randomPickIndex = (arr) => {
  return random(0, arr.length);
};

export const randomShuffle = (array) => {
  let ret = array.slice();
  for (var i = ret.length - 1; i > 0; i--) {
    var j = Math.floor(randomInternal() * (i + 1));
    var temp = ret[i];
    ret[i] = ret[j];
    ret[j] = temp;
  }
  return ret;
};
