const punctuations = ['.', ',', '!', '?', ':', ';']
const wordDelimiters = [...punctuations, ' ', '(', ')', '[', ']', "'", '"', '#']

export const stringSplitParagraph = txt => {
  const snippets = txt
    .split(/[\n\r]/g)
    .map(e => e.trim())
    .filter(e => e.length > 0)
  return snippets
}

export const stringIndexOfAll = (haystack, needle) => {
  // indexOf all occurrences of needle in haystack
  let found = []
  let pos = haystack.indexOf(needle)
  while (pos !== -1) {
    found.push(pos)
    pos = haystack.indexOf(needle, pos + 1)
  }
  return found
}

export const stringIsWord = (haystack, indexBegin, indexEnd) => {
  // true if [indexBegin,indexEnd] denotes a word in haystack
  if (indexBegin > 0 && ' ' !== haystack[indexBegin - 1]) {
    return false
  }
  // beginning is ok
  const indexPastCandidate = indexEnd + 1
  if (
    indexPastCandidate < haystack.length &&
    !wordDelimiters.includes(haystack[indexPastCandidate])
  ) {
    return false
  }
  // end is also ok
  return true
}

export const stringStartsWithAny = (str, startCandidates) => {
  if (startCandidates.length === 0) {
    return false
  }
  return startCandidates
    .map(s => str.startsWith(s))
    .reduce((acc, cur) => acc || cur)
}

export const stringReplaceChar = (original, index, replacement) => {
  if (original === '') {
    return original
  }
  return original.slice(0, index) + replacement + original.slice(index + 1)
}

export const stringCapitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
