import React from "react";
import cop from "../../static/cop.png";
import emojiBlush from "../../static/smiley-blush.svg";
import emojiHearts from "../../static/smiley-heart-eyes.svg";
import lock from "../../static/lock.svg";
import paypal from "../../static/paypal.svg";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { langInfoGet } from "../../helpers/lang";

export default class AboutLegal extends React.Component {
  constructor(props) {
    super(props);
    const { $$ } = this.props;
    this.licences = {
      CC0: {
        // Creative Commons CC0
        // You are free to adapt and use them for commercial purposes without attributing the original author or source.
        label: $$("CC0"),
        link: "https://creativecommons.org/publicdomain/zero/1.0/deed.en",
      },
      CCBY3: {
        // Creative Commons
        // Attribution 3.0 Unported
        // Attribution — You must give appropriate credit, provide a link to the license, and indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor endorses you or your use.
        label: $$("CC BY 3.0"),
        link: "https://creativecommons.org/licenses/by/3.0/",
      },
      CCBYSA3: {
        // Creative Commons
        // Attribution-ShareAlike 3.0 Unported
        // Attribution — Same as CCBY3
        // ShareAlike — If you remix, transform, or build upon the material, you must distribute your contributions under the same license as the original.
        label: $$("CC BY-SA 3.0"),
        link: "https://creativecommons.org/licenses/by-sa/3.0/",
      },
      GPL: {
        label: $$("GPL"),
        link: "https://www.gnu.org/copyleft/gpl.html",
      },
      free: {
        label: $$('"Free for commercial use"'),
        link:
          "https://blog.iconfinder.com/license-agreements-used-on-iconfinder-15b2890bbd45",
      },
      // NASA: {
      //   label: 'NASA Media Usage Guidelines',
      //   link: 'https://www.nasa.gov/multimedia/guidelines/index.html',
      // },
      Apache2: {
        label: $$("Apache License, Version 2.0"),
        link: "http://www.apache.org/licenses/LICENSE-2.0",
      },
    };
    this.credits = [
      {
        creator: {
          name: "Hopnguyen Mr",
          link: "https://www.iconfinder.com/Mr.hopnguyen",
        },
        items: [
          // {
          //   link:
          //     'https://www.iconfinder.com/icons/2634450/ensign_flag_kingdom_nation_icon',
          //   label: 'image of the British flag',
          //   licence: this.licences['CCBY3'],
          //   img: ukFlag,
          // },
          {
            link:
              "https://www.iconfinder.com/icons/2634451/ensign_flag_nation_states_icon",
            label: $$("image of the American flag"),
            licence: this.licences["CCBY3"],
            img: langInfoGet("english").flag.src,
            modified: true,
          },
          {
            link:
              "https://www.iconfinder.com/icons/2634428/ensign_flag_nation_sweden_icon",
            label: $$("image of the Swedish flag"),
            licence: this.licences["CCBY3"],
            img: langInfoGet("swedish").flag.src,
            modified: true,
          },
        ],
      },
      {
        creator: {
          name: "Everaldo Coelho",
          link: "http://www.everaldo.com/",
        },
        items: [
          {
            link: "https://www.iconfinder.com/icons/15694/cop_police_icon",
            label: $$('image of a cartoon cop looking all "TypoCop-ish"'),
            licence: this.licences["GPL"],
            img: cop,
            // mostly unmodified but in some cases the image background
            // has been changed to white (instead of transparent),
            // and the image has been resized
            modified: true,
          },
        ],
      },
      {
        creator: {
          name: "Lester Gonzales",
          link: "https://www.iconfinder.com/graphictea",
        },
        items: [
          {
            link:
              "https://www.iconfinder.com/icons/1325167/blush_emoji_emoticon_happy_joy_love_reaction_icon",
            label: $$("image of a blushing emoji"),
            licence: this.licences["free"],
            img: emojiBlush,
          },
          {
            link:
              "https://www.iconfinder.com/icons/1325176/emoji_emoticon_heart_like_love_reaction_valentine_icon",
            label: $$("image of a heart-eyed emoji"),
            licence: this.licences["free"],
            img: emojiHearts,
          },
        ],
      },
      {
        creator: {
          name: "Paomedia",
          link: "https://www.iconfinder.com/paomedia",
        },
        items: [
          {
            link: "https://www.iconfinder.com/icons/285646/lock_icon",
            label: $$("image of a lock"),
            licence: this.licences["CCBY3"],
            img: lock,
          },
        ],
      },
      {
        creator: {
          name: "CopyrightFreePictures",
          link: "https://pixabay.com/en/users/CopyrightFreePictures-203/",
        },
        items: [
          {
            link:
              "https://pixabay.com/en/paypal-logo-brand-pay-payment-784404/",
            label: $$("image of the PayPal logo"),
            licence: this.licences["CC0"],
            img: paypal,
          },
        ],
      },
      {
        creator: {
          name: "Google",
          link: "https://fonts.google.com/",
        },
        items: [
          {
            link: "https://fonts.google.com/specimen/Roboto",
            label: $$("font Roboto"),
            licence: this.licences["Apache2"],
          },
        ],
      },
    ];
  }

  renderBackgroundCredit = ({ bg }) => {
    const { $$ } = this.props;
    const { url, image, creator, licence, modified } = bg;
    const item = {
      link: url,
      label: $$("background image"),
      licence: this.licences[licence],
      img: image,
      modified,
    };
    const credit = {
      creator: {
        name: creator.name,
        link: creator.url,
      },
      items: [item],
    };
    return this.renderCredits("rank", [credit]);
  };

  renderMissIconCredit = ({ miss }) => {
    const { $$ } = this.props;
    const { url, image, creator, licence, modified } = miss;
    const item = {
      link: url,
      label: $$("image marking a mistake"),
      licence: this.licences[licence],
      img: image,
      modified,
    };
    const credit = {
      creator: {
        name: creator.name,
        link: creator.url,
      },
      items: [item],
    };
    return this.renderCredits("rank", [credit]);
  };

  renderRankCredits = ({ ranks }) => {
    const { $$, language } = this.props;
    let credits = [];
    for (let i = 0; i < ranks.length; ++i) {
      const rank = ranks[i];
      const { score, url, image, name, creator, licence, modified } = rank;
      const label = name[language];

      // Keep a separate credits list for rank credits.
      // Might mean that the same creator is listed twice, once for non-rank resources
      // and then again for rank resources, which is fine.
      let credit = credits.find((c) => c.creator.name === creator.name);
      if (!credit) {
        credit = {
          creator: {
            name: creator.name,
            link: url,
          },
          items: [],
        };
        credits.push(credit);
      }
      const item = {
        link: url,
        label: $$("image for rank {0} ({1}+ points)", label, score),
        licence: this.licences[licence],
        img: image,
        modified,
      };
      credit.items.push(item);
    }
    return this.renderCredits("rank", credits);
  };

  renderCredits = (type, credits) => {
    const { $$ } = this.props;
    const T = Typography;
    return credits.map((c, index) => (
      <div key={`credits-${type}-${index}`}>
        <T>
          {$$("Credit to") + " "}
          <a href={c.creator.link}>{c.creator.name}</a>
          {" " + $$("for") + ":"}
        </T>
        <List dense={true}>
          {c.items.map((i, index) => {
            const t = (
              <div>
                <a href={i.link}>{i.label}</a>
                {" " + $$("under the license") + " "}
                <a href={i.licence.link}>{i.licence.label}</a>
                {i.modified
                  ? " (" + $$("modified") + ")"
                  : " (" + $$("not modified") + ")"}
              </div>
            );
            return (
              <ListItem key={`credits-item-${index}`}>
                <ListItemText primary={t} />
              </ListItem>
            );
          })}
        </List>
      </div>
    ));
  };

  render() {
    const { $$, themeComponent, language, headerComponent } = this.props;
    const Header = headerComponent;
    const Theme = themeComponent;
    const T = Typography;
    const RF = React.Fragment;
    return (
      <div>
        <T>
          {language === "english" && (
            <RF>
              You are welcome to play TypoCop on typocop.com all you want for
              free, but if you want to do anything else with it you must be
              given permission from the copyright holder of TypoCop:{" "}
            </RF>
          )}
          {language === "swedish" && (
            <RF>
              Du får spela TypoCop på typocop.com så mycket du vill helt gratis,
              men om du vill göra något annat med det måste du erhålla tillstånd
              från TypoCops upphovs&shy;rätts&shy;innehavare:{" "}
            </RF>
          )}
          <a href="https://tom10.net">tom10</a>.
        </T>
        <T>
          {language === "english" && (
            <RF>
              The resources below are used on typocop.com in accordance with
              their respective licences. The copyright holders are not
              affiliated with TypoCop or typocop.com in any way. You are free to
              use these resources as long as you meet the demands of their
              respective licences.
            </RF>
          )}
          {language === "swedish" && (
            <RF>
              Resurserna nedan används på typocop.com i enlighet med deras
              respektive licenser. Upphovs&shy;rätts&shy;innehavarna är inte
              associerade med TypoCop eller typopcop.com på något sätt. Det står
              dig fritt att använda dessa resurser så länge du uppfyller kraven
              i deras respektive licenser.
            </RF>
          )}
        </T>

        <Header id="about-legal-resources-generic">
          {$$("Generic Resources")}
        </Header>
        <T>{$$("Resources that are not specific to the current challenge.")}</T>
        {this.renderCredits("generic", this.credits)}

        <Header id="about-legal-resources-challenge">
          {$$("Resources for Current Challenge")}
        </Header>
        <T>{$$("Resources used in the current challenge.")}</T>
        <Theme $$={$$} language={language} render={this.renderRankCredits} />
        <Theme $$={$$} language={language} render={this.renderMissIconCredit} />
        <Theme
          $$={$$}
          language={language}
          render={this.renderBackgroundCredit}
        />
      </div>
    );
  }
}
// Explanation on how to interpret the licences on iconfinder.com:
// https://blog.iconfinder.com/license-agreements-used-on-iconfinder-15b2890bbd45
