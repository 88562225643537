import React from "react";
import { Button } from "@material-ui/core";

export default function PlainButton({ children, onClick, ...props }) {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      style={{
        padding: "0px 10px 0px 10px",
        margin: "0px 0px 16px 0px",
        backgroundColor: "rgba(255,255,255,0.85)",
        textTransform: "none",
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
