// theme functions
import { dbHighScoreGet, dbThemeGet, dbThemeIdsGet } from "./db";

export const themeLevelsGet = (theme, language) => {
  const themeObj = dbThemeGet(theme);
  let previousLevelHighScore = null;
  return themeObj.levels.map((levelObj) => {
    const highScore = dbHighScoreGet(theme, language, levelObj.id);
    const label = levelObj.name[language].toUpperCase();
    const unlocked = levelObj.isUnlocked(previousLevelHighScore);
    previousLevelHighScore = highScore;
    return { highScore, label, unlocked, ...levelObj };
  });
};

export const themeLanguagesGet = (theme) => {
  const themeObj = dbThemeGet(theme);
  const { languages } = themeObj;
  return languages;
};

export const themeNameGet = (theme, language) => {
  const themeObj = dbThemeGet(theme);
  const { name } = themeObj;
  return language in name ? name[language] : name[Object.keys(name)[0]];
};

export const themeIdsGet = () => {
  return dbThemeIdsGet();
};

export const themeColorGet = (theme) => {
  const themeObj = dbThemeGet(theme);
  const { color } = themeObj;
  return color;
};

export const themeLoad = (theme, doOnSuccess, doOnError) => {
  if (theme === "halloween") {
    themeLoadHalloween(doOnSuccess, doOnError);
    return true;
  } else if (theme === "ww") {
    themeLoadWinterWonderland(doOnSuccess, doOnError);
    return true;
  } else if (theme === "spring-fever") {
    themeLoadSpringFever(doOnSuccess, doOnError);
    return true;
  } else {
    return false;
  }
};

const themeLoadHalloween = (doOnSuccess, doOnError) => {
  import(
    "../theme/halloween/halloween" /* webpackChunkName: "ThemeHalloween" */
  )
    .then((theme) => {
      doOnSuccess(theme);
    })
    .catch((err) => {
      doOnError(err.message);
    });
};

const themeLoadWinterWonderland = (doOnSuccess, doOnError) => {
  import(
    "../theme/winterwonderland/winterwonderland" /* webpackChunkName: "ThemeWinterWonderland" */
  )
    .then((theme) => {
      doOnSuccess(theme);
    })
    .catch((err) => {
      doOnError(err.message);
    });
};

const themeLoadSpringFever = (doOnSuccess, doOnError) => {
  import(
    "../theme/spring-fever/spring-fever" /* webpackChunkName: "ThemeSpringFever" */
  )
    .then((theme) => {
      doOnSuccess(theme);
    })
    .catch((err) => {
      doOnError(err.message);
    });
};
