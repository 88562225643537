import React from "react";
import TCDialog from "./TCDialog";
import {
  themeNameGet,
  themeIdsGet,
  themeLevelsGet,
  themeLanguagesGet,
  themeColorGet,
} from "../helpers/theme";
import { Button, Typography } from "@material-ui/core";

import { Style } from "./Common";
import { langInfoGet } from "../helpers/lang";

export default class Themes extends React.Component {
  onSelect = (theme) => {
    if (this.props.onSelect) {
      this.props.onSelect(theme);
    }
    window.location.href = "/"; // reload
  };
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
  render() {
    const { open, $$, language } = this.props;
    const themes = themeIdsGet();
    return (
      <TCDialog
        title={$$("CHALLENGES")}
        open={open}
        $$={$$}
        onClose={this.onClose}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            margin: "20px 0px 0px 0px",
          }}
        >
          {themes.map((theme) => {
            const color = themeColorGet(theme);
            const name = themeNameGet(theme, language);
            const languages = themeLanguagesGet(theme);
            return (
              <div
                key={theme}
                style={{
                  padding: "4px 0px 0px 0px",
                  width: "100%",
                }}
              >
                <Button
                  variant="text"
                  style={{
                    textTransform: "none",
                    padding: 0,
                    width: "100%",
                    margin: 0,
                  }}
                  onClick={() => this.onSelect(theme)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 4,
                      backgroundColor: color,
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Typography variant="h3">{name}</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      {languages.map((language) => {
                        const levels = themeLevelsGet(theme, language);
                        if (
                          levels
                            .map((level) => level.highScore === -1)
                            .reduce((acc, cur) => acc && cur)
                        ) {
                          return null;
                        }

                        const flag = langInfoGet(language).flag;
                        return (
                          <div
                            key={`highscores-${language}`}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-end",
                              padding: 2,
                            }}
                          >
                            <img
                              src={flag.src}
                              alt={$$(flag.alt)}
                              style={{ width: 16 }}
                            />
                            {levels.map((level) => {
                              return (
                                <Typography
                                  key={`highscore-${language}-${level.id}`}
                                  style={{
                                    backgroundColor:
                                      Style.levels[level.id].color,
                                  }}
                                >
                                  {level.highScore >= 0 ? level.highScore : "-"}
                                </Typography>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Button>
              </div>
            );
          })}
        </div>
      </TCDialog>
    );
  }
}
