import React from "react";
import { Typography } from "@material-ui/core";
import AboutLegal from "./AboutLegal";
import AboutPrivacy from "./AboutPrivacy";

function Header(props) {
  const { children } = props;
  return (
    <Typography style={{ marginTop: 20 }} variant="h2">
      {children}
    </Typography>
  );
}

function SubHeader(props) {
  const { children } = props;
  return (
    <Typography style={{ marginTop: 20 }} variant="h3">
      {children}
    </Typography>
  );
}

export default class AboutAbout extends React.Component {
  render() {
    const { $$, language, themeComponent } = this.props;
    const T = ({ children, ...props }) => (
      <Typography gutterBottom {...props}>
        {children}
      </Typography>
    );
    const version = process.env.REACT_APP_VERSION;
    const buildTime = new Date(process.env.REACT_APP_BUILD_TIME)
      .toISOString()
      .slice(0, 10);
    const versionString = `${version}, built on ${buildTime}`;
    const isSwedish = language === "swedish";
    return (
      <div>
        <T>{$$("TypoCop version {0}.", versionString)}</T>
        <ul>
          <li>
            <T>
              <a href="#release-notes">{$$("Release notes")}</a>
            </T>
          </li>
          <li>
            <T>
              <a href="#legal">{$$("Legal")}</a>
            </T>
          </li>
          <li>
            <T>
              <a href="#privacy">{$$("Privacy")}</a>
            </T>
          </li>
          <li>
            <T>
              <a href="#contact">{$$("Contact")}</a>
            </T>
          </li>
        </ul>
        <Header id="release-notes">{$$("Release notes")}</Header>
        {isSwedish && (
          <T>
            {
              /* spell-checker: disable */
              "Förändringsprotokollet finns tyvärr bara på engelska."
              /* spell-checker: enable */
            }
          </T>
        )}
        <SubHeader>Release 1.4.3 on 2020-04-03</SubHeader>
        <T>Fix links to author (https://tom10.net).</T>
        <SubHeader>Release 1.4.2 on 2019-07-19</SubHeader>
        <T>The "support me" dialogues have been removed.</T>
        <T>The contact information has been updated.</T>
        <SubHeader>Release 1.4.1 on 2019-04-08</SubHeader>
        <T>
          Default theme is now the spring fever theme. (Was the Halloween theme)
        </T>
        <T>
          In the Swedish version of the spring fever theme the word "vårfeber"
          was replaced with "vårkänslor".
        </T>
        <SubHeader>Release 1.4.0 on 2019-04-06</SubHeader>
        <T>
          A new challenge has been released. It's called "Spring Fever". Click
          on the currently active challenge on the start screen to see all
          available challenges.
        </T>
        <SubHeader>Release 1.3.1 on 2018-11-03</SubHeader>
        <T>
          The about page no longer shows the images in the credit list. The
          images were removed to boost the page loading speed.
        </T>
        <T>
          On the game screen, it could happen that a line break appeared between
          the challenge name and the level name. This has now been fixed.
        </T>
        <SubHeader>Release 1.3.0 on 2018-10-31</SubHeader>
        <T>
          A new challenge has been released. It's called "Winter Wonderland".
          Click on the currently active challenge on the start screen to see all
          available challenges.
        </T>
        <SubHeader>Release 1.2.0 on 2018-10-10</SubHeader>
        <T>
          The game report includes a chart of how much time you "spent" in each
          round, including any time penalty incurred if you made a mistake.
        </T>
        <T>
          TypoCop can fail to load some resources when a new version has been
          published. If this happens, TypoCop informs the user and requests them
          to reload.
        </T>
        <SubHeader>Release 1.1.1 on 2018-10-09</SubHeader>
        <T>
          The font size of the "typo-sentences" on the game screen can be
          adjusted in Settings. Also, the default font size is increased "one
          step". These changes only affects the font size of the sentences with
          typos.
        </T>
        <T>
          The game screen shows the chosen level of difficulty underneath the
          TypoCop logo.
        </T>
        <T>
          On the game screen, the "typo-sentences" have more empty space around
          them. Hopefully, this makes it easier to focus on the sentences, and
          easier to hit the words closest to the edges of the screen.
        </T>
        <T>
          The buttons "LEGAL" and "PRIVACY" (on the home page) have been
          replaced with a single "ABOUT" button. The ABOUT button opens a dialog
          with the following information: release notes, legal, privacy, and
          contact information. The sections about release note and contact
          information are new. The legal and privacy sections contain (more or
          less) the same information as before.
        </T>
        <T>Accept support via PayPal.</T>
        <T>
          On Apple devices, "Add to home screen" no longer (erroneously)
          includes "index.html".
        </T>
        <T>
          On older mobile devices, "Add to home screen" uses an icon with better
          resolution.
        </T>
        <T>
          The button at the bottom of dialogs has the label "CLOSE". The button
          used to have the label "OK".
        </T>
        {/* Older versions above this line */}
        <Header id="legal">{$$("Legal")}</Header>
        <AboutLegal
          $$={$$}
          language={language}
          themeComponent={themeComponent}
          headerComponent={SubHeader}
        />
        <Header id="privacy">{$$("Privacy")}</Header>
        <AboutPrivacy
          $$={$$}
          language={language}
          themeComponent={themeComponent}
          headerComponent={SubHeader}
        />
      </div>
    );
  }
}
