import React from 'react'
import TCNumber from './TCNumber'

export default class Countdown extends React.Component {
  constructor(props) {
    super(props)
    this.dtms = this.props.dt * 1000 // set once, cannot be changed via dt prop later
    this.state = { elapsedms: 0 }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 50)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  onDone = () => {
    if (this.props.onDone) {
      this.props.onDone()
    }
  }

  tick = () => {
    const elapsedms = Date.now() - this.props.start + 1000 * this.props.penalty
    this.setState({ elapsedms })
    if (elapsedms >= this.dtms) {
      clearInterval(this.timerID)
      this.onDone()
    }
  }

  render() {
    const { elapsedms } = this.state
    let secondsleft = Math.ceil((this.dtms - elapsedms) / 1000)
    secondsleft = secondsleft < 0 ? 0 : secondsleft
    return (
      <TCNumber
        variant={secondsleft < 10 ? 'countdownDanger' : 'countdownNormal'}
        align={this.props.align}
        number={secondsleft}
      />
    )
  }
}
