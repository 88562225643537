import React from "react";
import Typography from "@material-ui/core/Typography";

export default class AboutHowToPlay extends React.Component {
  renderThemeMiss = ({ miss }) => {
    const { $$ } = this.props;
    return <img src={miss.image} style={{ width: 32 }} alt={$$("Miss icon")} />;
  };
  render() {
    const { $$, themeComponent, language } = this.props;
    const Theme = themeComponent;
    const T = ({ children, ...props }) => (
      <Typography gutterBottom {...props}>
        {children}
      </Typography>
    );
    const RF = React.Fragment;
    const isEnglish = language === "english";
    const isSwedish = language === "swedish";
    return (
      <div>
        <T variant="h2">{$$("Welcome to TypoCop!")}</T>
        <T>{$$("This is what you do:")}</T>
        <T>{$$("1. Find the typo.")}</T>
        <T>{$$("2. Tap/Click on the typo.")}</T>
        <T>{$$("3. Repeat 1-2 until the clock runs out.")}</T>
        <T>
          {isEnglish && (
            <RF>If you make a mistake you LOSE 2 SECONDS and this is shown:</RF>
          )}
          {isSwedish && (
            <RF>
              Om du begår ett misstag FÖRLORAR DU 2 SEKUNDER och detta visas:
            </RF>
          )}
        </T>
        <Theme $$={$$} language={language} render={this.renderThemeMiss} />
        <T>{$$("Do you want to know more? Read on.")}</T>

        <T variant="h2">{$$("The Details")}</T>
        <T>
          {isEnglish && (
            <RF>
              In TypoCop, you need to find the typo hiding somewhere in a
              sentence. Make your choice and tap/click on the word you think is
              spelled wrong. You get one point if you are correct but if you are
              wrong you lose two seconds.
            </RF>
          )}
          {isSwedish && (
            <RF>
              I TypoCop ska du hitta skrivfelet som gömmer sig någonstans i en
              mening. Gör ditt val och tryck/klicka på ordet som du tror är
              felstavat. Du får en poäng om du har rätt men om du har fel
              förlorar du två sekunder.
            </RF>
          )}
        </T>
        <T>
          {isEnglish && (
            <RF>
              If you still have time left you get another sentence with another
              typo to find.
            </RF>
          )}
          {isSwedish && (
            <RF>
              Om du har tid kvar så visas en annan mening med ett annat
              skrivfel.
            </RF>
          )}
        </T>
        <T>
          {isEnglish && (
            <RF>You start the game with 30 seconds on the clock.</RF>
          )}
          {isSwedish && (
            <RF>Du har totalt 30 sekunder på dig att leta skrivfel.</RF>
          )}
        </T>
        <T>
          {isEnglish && (
            <RF>
              Afterwards, TypoCop assigns you a rank based on your performance.
              You can replay the challenge to improve your score and possibly
              your rank. You can also view the game report to see which typos
              you missed.
            </RF>
          )}
          {isSwedish && (
            <RF>
              Efteråt tilldelar TypoCop dig en ranking baserat på din
              prestation. Du kan då välja att spela igen för att få högre poäng
              och en bättre ranking. Du kan också öppna spelrapporten för att se
              vilka skrivfel du missade.
            </RF>
          )}
        </T>
        <T>
          {isEnglish && (
            <RF>TypoCop is intended to be played using a touchscreen.</RF>
          )}
          {isSwedish && <RF>TypoCop är avsedd att spelas på en touchskärm.</RF>}
        </T>
        <T>
          {isEnglish && <RF>TypoCop saves your high score in your browser.</RF>}
          {isSwedish && (
            <RF>TypoCop sparar ditt bästa resultat i din webbläsare.</RF>
          )}
        </T>
      </div>
    );
  }
}
