import React from "react";
import Game from "../components/Game";
import Bar from "../components/Bar";
import { TypoTextGet, TypoMarker } from "../helpers/game";
import Countdown from "../components/Countdown";
import GameFinished from "../components/GameFinished";
import Screen from "../components/Screen";
import { randomShuffle, randomSeedReset } from "../helpers/random";
import {
  dbHighScoreUpdate,
  dbGamesPlayedCountIncrement,
  dbChallengeIndexAdd,
} from "../helpers/db";
import { themeNameGet } from "../helpers/theme";

export default class GameScreen extends React.Component {
  constructor(props) {
    super(props);
    randomSeedReset(this.props.location.state.seed);
    this.challenge = Object.assign({}, this.props.location.state.challenge);
    this.challengeId = this.props.location.state.challengeId;
    this.challenge.snippet = randomShuffle(this.challenge.snippet);
    this.state = this.newGameStateGet();
    this.penaltySeconds = 2;
  }

  newGameStateGet = () => {
    const gameRound = 1;
    const { bloomfilter, language } = this.props;
    const text = TypoTextGet(this.challenge, gameRound, bloomfilter, language);
    const timeStart = new Date();
    const timeInitiated = timeStart.getTime();
    return {
      gameRound,
      score: 0,
      challengeCompleted: false,
      challengeMissCounter: 0,
      timeInitiated,
      text,
      game: { rounds: [], timeStart, countdown: this.challenge.countdown },
      newHighScore: false,
      newLevelUnlocked: false,
    };
  };

  recordGameRound(typotext, chosenIndex, found) {
    const { game } = this.state;
    const typoIndex = typotext
      .split(" ")
      .findIndex((w) => w.includes(TypoMarker));
    const text = typotext.replace(TypoMarker, "");
    const time = new Date();
    game.rounds.push({
      text,
      typoIndex,
      chosenIndex,
      time,
      penalty: found ? 0 : this.penaltySeconds,
    });
    this.setState({ game });
  }

  onSameAgain = () => {
    randomSeedReset(this.props.location.state.seed);
    this.challenge = Object.assign({}, this.props.location.state.challenge);
    // this.challengeId = this.props.location.state.challengeId;
    this.challenge.snippet = randomShuffle(this.challenge.snippet);
    this.setState(this.newGameStateGet());
  };

  onExit = () => {
    const { history, makeUrl } = this.props;
    history.push(makeUrl(""));
  };

  onWin = (wordIndex) => {
    const { bloomfilter, language } = this.props;
    let { gameRound, score, text } = this.state;
    const found = true;
    this.recordGameRound(text, wordIndex, found);
    gameRound += 1;
    score += 1;
    const nexttext = TypoTextGet(
      this.challenge,
      gameRound,
      bloomfilter,
      language
    );
    this.setState({
      gameRound,
      score,
      text: nexttext,
    });
  };

  onMiss = (wordIndex) => {
    // player missed
    const { bloomfilter, language } = this.props;
    let { gameRound, challengeMissCounter, text } = this.state;
    const found = false;
    this.recordGameRound(text, wordIndex, found);
    gameRound += 1;
    challengeMissCounter += 1;
    const nexttext = TypoTextGet(
      this.challenge,
      gameRound,
      bloomfilter,
      language
    );
    this.setState({ gameRound, challengeMissCounter, text: nexttext });
  };

  onChallengeTimeout = () => {
    const { score } = this.state;
    const { language, themeComponent } = this.props;
    const { level } = this.challenge;
    const [newHighScore, newLevelUnlocked] = dbHighScoreUpdate(
      themeComponent.id,
      language,
      level,
      score,
      this.challengeId
    );
    if (this.challengeId) {
      dbChallengeIndexAdd(this.challengeId);
    }
    dbGamesPlayedCountIncrement();
    const challengeCompleted = true;
    this.setState({ challengeCompleted, newHighScore, newLevelUnlocked });
  };

  renderThemeMiss = ({ miss }) => {
    const { $$ } = this.props;
    const { challengeMissCounter } = this.state;
    return (
      <div>
        {[...Array(challengeMissCounter).keys()].map((i) => (
          <img
            key={`miss-marker-${i}`}
            src={miss.image}
            style={style.missIcon}
            alt={$$("Miss")}
          />
        ))}
      </div>
    );
  };

  render() {
    const { $$, makeUrl, themeComponent, language } = this.props;
    const {
      gameRound,
      score,
      challengeCompleted,
      timeInitiated,
      text,
      challengeMissCounter,
      game,
      newHighScore,
      newLevelUnlocked,
    } = this.state;

    const challenge = this.challenge;
    const Theme = themeComponent;
    const challengeName = themeNameGet(challenge.theme, language);
    return (
      <Screen>
        <Bar
          score={score}
          countdown={
            challenge.countdown > 0 ? (
              <Countdown
                key={`countdown-${timeInitiated}`}
                start={timeInitiated}
                dt={challenge.countdown}
                onDone={this.onChallengeTimeout}
                penalty={this.penaltySeconds * challengeMissCounter}
                align="right"
              />
            ) : /* give Countdown a key-prop to reset it on every screen */
            null
          }
          title={
            $$(challengeName).toUpperCase() +
            " - " +
            $$(challenge.level).toUpperCase()
          }
          challengeId={this.challengeId}
        />

        {challengeCompleted ? (
          <GameFinished
            onSameAgain={this.onSameAgain}
            score={score}
            onExit={this.onExit}
            challenge={challenge}
            $$={$$}
            language={language}
            makeUrl={makeUrl}
            game={game}
            themeComponent={themeComponent}
            newHighScore={newHighScore}
            newLevelUnlocked={newLevelUnlocked}
            challengeId={this.challengeId}
          />
        ) : (
          <div style={style.columnSpread}>
            <Game
              key={`game-${timeInitiated}-${gameRound}`}
              s={text}
              typoMarker={TypoMarker}
              onWin={this.onWin}
              onMiss={this.onMiss}
            />
            <Theme language={language} render={this.renderThemeMiss} />
          </div>
        )}
      </Screen>
    );
  }
}

const style = {
  columnSpread: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "70vh",
    paddingTop: 20,
  },
  missIcon: {
    width: 64,
  },
};
