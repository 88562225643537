import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import GameScreen from "./screens/GameScreen";
import ChallengeScreen from "./screens/ChallengeScreen";
import { $$ } from "./helpers/lang";
// import TestScreen from './screens/TestScreen'

class App extends Component {
  constructor(props) {
    super(props);
    this.bloomfilter = null;
    this.config = null;
    this.themeComponent = null;
  }

  saveBloomfilter = (bloomfilter) => {
    this.bloomfilter = bloomfilter;
  };

  saveConfig = (config) => {
    this.config = config;
  };

  saveTheme = (themeComponent) => {
    this.themeComponent = themeComponent;
  };

  bloomfilterGet = () => {
    return this.bloomfilter;
  };

  configGet = () => {
    return this.config;
  };

  renderHomeScreen = (language) => (props) => {
    const autoRedirectHome = false;
    return this.renderScreen(
      HomeScreen,
      language,
      autoRedirectHome
    )({
      ...props,
      saveBloomfilter: this.saveBloomfilter,
      saveConfig: this.saveConfig,
      saveTheme: this.saveTheme,
    });
  };

  renderChallengeScreen = () => (props) => {
    const autoRedirectHome = false;
    return this.renderScreen(
      ChallengeScreen,
      null,
      autoRedirectHome
    )({
      ...props,
      saveBloomfilter: this.saveBloomfilter,
      saveConfig: this.saveConfig,
      saveTheme: this.saveTheme,
    });
  };

  makeUrl = (language) => (url) => {
    const urlprefix = {
      swedish: "/se",
    };
    const code = language in urlprefix ? urlprefix[language] : "";
    return `${code}/${url}`;
  };

  renderScreen = (ScreenComponent, language, autoRedirectHome = true) => (
    props
  ) => {
    if (
      autoRedirectHome &&
      (this.config === null || this.bloomfilter === null)
    ) {
      return this.renderHomeScreen(language)(props);
    }
    return (
      <ScreenComponent
        {...props}
        key={language}
        bloomfilter={this.bloomfilterGet()}
        config={this.configGet()}
        language={language}
        $$={$$(language)}
        makeUrl={this.makeUrl(language)}
        themeComponent={this.themeComponent}
      />
    );
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={this.renderHomeScreen("english")} />

          <Route exact path="/se" render={this.renderHomeScreen("swedish")} />

          <Route
            exact
            path="/game"
            render={this.renderScreen(GameScreen, "english")}
          />

          <Route
            exact
            path="/se/game"
            render={this.renderScreen(GameScreen, "swedish")}
          />

          <Route path="/c/:id" render={this.renderChallengeScreen()} />

          {/* <Route
            exact
            path="/admin-test-words"
            render={this.renderScreen(TestScreen, 'english')}
          />

          <Route
            exact
            path="/se/admin-test-words"
            render={this.renderScreen(TestScreen, 'swedish')}
          /> */}

          <Route render={this.renderHomeScreen("english")} />
        </Switch>
      </Router>
    );
  }
}

export default App;
