import {
  stringIndexOfAll,
  stringIsWord,
  stringReplaceChar,
  stringSplitParagraph,
} from '../helpers/string'
import { randomBool, randomPick, randomShuffle } from '../helpers/random'
import * as keyboard from '../helpers/keyboard'

// PRIVATE

const TypoTextManual = (text, typos) => {
  const candidates = Object.keys(typos)
  let valid = []
  candidates.forEach(candidate => {
    stringIndexOfAll(text, candidate).forEach(index => {
      if (stringIsWord(text, index, index + candidate.length - 1)) {
        // match must be a word, not part of a word
        valid.push({ candidate, index })
      }
    })
  })
  const selected = randomPick(valid)
  const typoChoices = typos[selected.candidate]
  const typo = randomPick(typoChoices)
  const typotext =
    text.slice(0, selected.index) +
    typo +
    TypoMarker +
    text.slice(selected.index + selected.candidate.length)
  return typotext
}

const TypoText = (realText, bloomfilter, keyboardNeighborsFun) => {
  // return text with one automatically generated typo
  // assume the sentence has been pre-analyzed and approved, i.e.
  // NO NEED TO CHECK THAT TEXT IS VALID
  const words = realText.split(' ')
  let candidates = randomShuffle(words.map((w, i) => i)) // .filter(wi => words[wi].length >= 2)
  for (let i = 0; i < candidates.length; ++i) {
    const chosenIndex = candidates[i]
    const chosenWord = words[chosenIndex].toLowerCase()
    const capitalize = chosenIndex === 0
    const typos = TypoWord(
      chosenWord,
      bloomfilter,
      keyboardNeighborsFun,
      capitalize
    )
    if (typos.length < 1) {
      // could not generate any typos from that word, pick another...
      continue
    }
    const typoWord = randomPick(typos) + TypoMarker
    words[chosenIndex] = typoWord
    break
  }

  const text = words.join(' ')
  return text
}

const TypoStrategySwapTwoLetters = word => {
  // strategy: swap two letters
  if (word.length < 2) {
    return []
  }
  let typos = []
  for (let i = 0; i < word.length - 1; i++) {
    if (word[i] === word[i + 1]) {
      // e.g. prevent "hello" from producing "hello" ("l" swapped with "l") as a candidate
      continue
    }
    const candidate =
      word.slice(0, i) + word[i + 1] + word[i] + word.substring(i + 2)
    typos.push(candidate)
  }
  return typos
}

const TypoStrategyMissLetterFun = word => {
  // letter was missed
  if (word.length < 3) {
    return []
  }
  let typos = []
  for (let i = 0; i < word.length; ++i) {
    if (word[i] === '-') {
      continue // too difficult to detect spelling errors where "-" has been removed
    }
    const typoword = word.slice(0, i) + word.slice(i + 1)
    typos.push(typoword)
  }
  return typos
}

const TypoStrategyNeighborLetterHitInsteadFun = keyboardNeighborsFun => word => {
  // intended letter was missed and another was hit instead
  if (word.length < 2) {
    return []
  }
  let typos = []
  for (let i = 0; i < word.length; i++) {
    if (word[i] === '-') {
      continue // too weird typos if neighbors to "-" are hit instead
    }
    const letter = word[i]
    const neighbors = keyboardNeighborsFun(letter)
    const newtypos = neighbors.map(n => stringReplaceChar(word, i, n))
    typos = [...typos, ...newtypos]
  }
  return typos
}

const TypoStrategySameLetterTwice = word => {
  // the same key was accidentally hit twice
  if (word.length < 2) {
    return []
  }
  let typos = []
  for (let i = 0; i < word.length; i++) {
    if (word[i] === '-') {
      continue // too weird to have "--" as a typo
    }
    const typoword = word.slice(0, i) + word[i] + word.slice(i)
    typos.push(typoword)
  }
  return typos
}

const TypoWordTypos = (word, strategy) => {
  // generate possible typos from word using given strategy
  let typos = []
  for (let i = 0; i < strategy.order.length; i++) {
    const s = strategy.order[i]
    const newtypos = s.typosGet(word)
    const newtyposkeep = newtypos.filter(t => !strategy.test(t))
    typos = [...typos, ...newtyposkeep]
    if (s.stopTest(typos)) {
      break
    }
  }
  return typos
}

const TypoUtilCoreWord = word => {
  // function is far more powerful than we actually need since the
  // only valid chars are: "a-zåäöA-ZÅÄÖ., "
  // ï: se+en
  // ç: se+en
  // ô: se+en
  // é: se+en
  // è: se+en
  // È: se+en
  // ë: se
  // ö: se+en
  // å: se+en
  // ä: se+en
  // û: se+en
  // ü: se+en
  const match = word.match(
    /^(['"([]*)([a-zåäöA-ZÅÄÖïçôéèëûü-]+)(['").,;:!?\]]*)/
  )
  if (!match) {
    return ['', '', '']
  }
  let [, prefix, coreword, suffix] = match
  return [prefix, coreword, suffix]
}

const TypoStrategyCreate = (keyboardNeighborsFun, bloomfilter) => {
  // create default strategy for generating typos
  // will generate all possible typos, set stopTest if that is not desired
  const order = [
    {
      id: 'TypoStrategySwapTwoLetters',
      typosGet: TypoStrategySwapTwoLetters,
      stopTest: typos => false, // false=do not stop
    },
    {
      id: 'TypoStrategyNeighborLetterHitInstead',
      typosGet: TypoStrategyNeighborLetterHitInsteadFun(keyboardNeighborsFun),
      stopTest: typos => false,
    },
    {
      id: 'TypoStrategySameLetterTwice',
      typosGet: TypoStrategySameLetterTwice,
      stopTest: typos => false,
    },
    {
      id: 'TypoStrategyMissLetterFun',
      typosGet: TypoStrategyMissLetterFun,
      stopTest: typos => false,
    },
  ]
  const test = bloomfilter.test.bind(bloomfilter)
  const strategy = { order, test }
  return strategy
}

const TypoWord = (
  originalWord,
  bloomfilter,
  keyboardNeighborsFun,
  capitalize,
  allTypos = false
) => {
  if (originalWord.length < 2) {
    return []
  }
  let [prefix, word, suffix] = TypoUtilCoreWord(originalWord)
  const strategy = TypoStrategyCreate(keyboardNeighborsFun, bloomfilter)
  if (!allTypos) {
    // 80 % stop chance if typo(s) were created when swapping letters
    strategy.order.find(
      o => o.id === 'TypoStrategySwapTwoLetters'
    ).stopTest = typos => typos.length > 0 && randomBool(80)
  }
  let typos = TypoWordTypos(word, strategy)
  if (capitalize) {
    typos = typos.map(typo => typo.replace(/^\w/, c => c.toUpperCase()))
  }
  typos = typos.map(typo => prefix + typo + suffix)
  return typos
}

const TypoCore = (language, word) => {
  const coreword = TypoUtilCoreWord(word)[1]
  const lce = {} // lower case exceptions
  lce['english'] = ['I']
  if (language in lce && lce[language].includes(coreword)) {
    return coreword
  } else {
    return coreword.toLowerCase()
  }
}

const TypoValidCore = (language, param, bloomfilter) => {
  // param is plain text, one sentence.
  // valid if
  // - first letter is capital letter, but when lowercased it is a valid word
  // - has only the following characters (after first letter lowercased): a-zåäö.,
  // - NO numbers, parenthesis, brackets, etc.
  // - ends with a single .
  // - at most a single ,
  // - all words are valid (first word lowercased first)

  const assert = (boolExpr, message) => {
    if (!boolExpr) {
      throw message
    }
  }

  // only allowed chars?
  const charCheck = param.split('').map(l => l.match(/[a-zåäöA-ZÅÄÖ,. -]/))
  const charsOk = charCheck.reduce((acc, cur) => acc && cur)
  assert(charsOk, 'Contains invalid chars')

  // comma
  const ncommas = (param.match(/,/g) || []).length
  assert(ncommas <= 2, 'At most two commas')

  // period
  const nperiods = (param.match(/\./g) || []).length
  assert(nperiods === 1, 'Exactly one period')
  assert(param.slice(-1) === '.', 'Dot must be at end of sentence')

  // hyphen
  const nhyphs = (param.match(/-/g) || []).length
  assert(nhyphs <= 1, 'At most one hyphen')
  assert(param.slice(-1) !== '-', 'No hyphen at end of sentence.')
  assert(param[0] !== '-', 'No hyphen at start of sentence.')
  assert((param.match(/- /g) || []).length === 0, 'No - at end of word')
  assert((param.match(/ -/g) || []).length === 0, 'No - at start of word')

  // capital letter
  const firstWordCaps = (param.split(' ')[0].match(/[A-ZÅÄÖ]/g) || []).length
  assert(firstWordCaps === 1, 'Exactly one capital letter in first word')
  assert(param[0].match(/[A-ZÅÄÖ]/), 'First letter must be capitalized')
  if (language === 'english') {
    // "I" is allowed to appear anywhere in the sentence.
    const ncapsnoti = (
      param
        .slice(1) // drop first capital letter
        .split(' ')
        .filter(p => p !== 'I') // skipp all "I" words
        .join(' ')
        .match(/[A-Z]/g) || []
    ).length
    assert(ncapsnoti === 0, 'Too many capital letters')
  } else {
    const ncaps = (param.match(/[A-ZÅÄÖ]/g) || []).length
    assert(ncaps === 1, 'Exactly one capital letter')
  }

  // six+ words
  const wordcount = param.split(' ').length
  assert(wordcount >= 6, 'Must have 6+ words')

  const badspell = param
    .split(' ')
    .filter(w => !bloomfilter.test(TypoCore(language, w)))
  assert(badspell.length === 0, `Spelling: ${badspell}`)
  return
}

// PUBLIC

// TESTAPI exposes the private functions for TESTING PURPOSES ONLY
export const TESTAPI = {
  TypoStrategySwapTwoLetters,
  TypoStrategyNeighborLetterHitInsteadFun,
  TypoStrategySameLetterTwice,
  TypoStrategyMissLetterFun,
  TypoUtilCoreWord,
  TypoStrategyCreate,
  TypoWordTypos,
  TypoWord,
}

export const TypoMarker = '*****'

export const TypoTextGet = (challenge, gameRound, bloomfilter, language) => {
  // gameRound: 1+
  // challenge.typos (obj), each key is one or more words, value is array of typos for that word
  // ret text (string) with the typo(s) marked with TypoMarker as suffix
  const keyboardNeighborsFun = {
    swedish: keyboard.keyboardNeighborsSwedish,
    english: keyboard.keyboardNeighborsEnglish,
  }

  const snippetId = (gameRound - 1) % challenge.snippet.length
  const text = challenge.snippet[snippetId]
  if (text.includes(TypoMarker)) {
    // text already includes marker so there is nothing to do
    return text
  }
  let errortext = ''
  if (challenge.typos.length > 0) {
    errortext = TypoTextManual(text, challenge.typos)
  } else {
    errortext = TypoText(text, bloomfilter, keyboardNeighborsFun[language])
  }

  return errortext
}

export const TypoValid = (language, text, bloomfilter) => {
  try {
    TypoValidCore(language, text, bloomfilter)
  } catch (e) {
    const errmsg = `${text} INVALID: ${e}`
    return [false, errmsg]
  }
  return [true, '']
}

export const gameChallengeCreate = (config, text, level, theme) => {
  const { countdown } = config
  const snippet = stringSplitParagraph(text).map(p => p.trim())
  const challenge = {
    level,
    countdown,
    snippet,
    skip: [],
    typos: [],
    theme,
  }
  return challenge
}

export const TypoScore = (language, txt) => {
  const stat = (language, words) => {
    const count = words.length
    const wls = words.map(w => TypoCore(language, w).length)
    const easy = wls.filter(wl => wl < 5).length
    const medium = wls.filter(wl => [5, 6, 7].includes(wl)).length
    const hard = wls.filter(wl => [8, 9, 10].includes(wl)).length
    const vhard = wls.filter(wl => [11, 12, 13].includes(wl)).length
    const insane = wls.filter(wl => wl > 13).length
    return [count, easy, medium, hard, vhard, insane]
  }
  const label = score => {
    const ls = [[0, 'easy'], [15, 'medium'], [26, 'hard'], [40, 'insane']]
    for (let i = ls.length - 1; i > 0; --i) {
      const [lscore, llabel] = ls[i]
      if (score >= lscore) {
        return llabel
      }
    }
    return ls[0][1]
  }

  const words = txt.split(' ')
  const stval = stat(language, words)
  const w = [1, 0, 2, 4, 8, 999999] // weights: word-count, easy, medium, hard, vhard, insane
  let score = 0
  for (let i = 0; i < stval.length; ++i) {
    score += stval[i] * w[i]
  }
  return [score, label(score)]
}
